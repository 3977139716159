import React, { useState, useContext } from 'react'
import LoginHandler from 'components/layouts/LoginHandler'
import { useDocumentTitle } from '../../components/hooks/useDocumentTitle'
import { useTranslation } from 'react-i18next'

const Start = () => {
  const { t } = useTranslation()
  useDocumentTitle(t('start.page_title'))

  return (
    <div className="page--start">
      <div className="logo"></div>
      <div className="card-warpper-row">
        <LoginHandler
          title={'landingFlow.page_1_title'}
          subTitle={'landingFlow.page_1_subtitle'}
          inputs={['email']}
          cta={t('forgot.continue')}
          action={'check-email'}
        />
      </div>
    </div>
  )
}

export default Start
