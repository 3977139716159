import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, InputGroup } from 'reactstrap'
import { LoadingContext } from 'contexts/loading'
import { Link } from 'react-router-dom'
import { $unsecurePost } from 'components/hooks/useAxios'
import support from 'assets/media/support.svg'
import emailSent from 'assets/media/email-sent.svg'
import { config } from 'configs/constants'
import { useEffect } from 'react'
import { handleEmailValidation } from 'utils/utils'
import { useDocumentTitle } from '../../components/hooks/useDocumentTitle'
import i18n from 'i18next'

const Register = ({ title }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useContext(LoadingContext) //eslint-disable-line
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [isEmailExist, setIsEmailExist] = useState(false)
  const [success, setSuccess] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(false)
  let url = `${config.urls.ACCOUNT_URL}/api/can-register?email=${email}&language=${i18n.language}`

  useDocumentTitle(t('register.page_title'))

  const checkUser = () => {
    if (isEmailValid) {
      setLoading(true)
      let alreadySent = window.sessionStorage.getItem('a')

      if (alreadySent === 'true') {
        url = `${config.urls.ACCOUNT_URL}/api/resend-verify-email-link?email=${email}&language=${i18n.language}`
      }
      $unsecurePost(url).then(
        (result) => {
          setLoading(false)
          setEmailError(false)
          setSuccess(true)
          window.sessionStorage.setItem('a', true)
        },
        (error) => {
          if (error.status === 404) {
            setEmailError(true)
          }

          if (error.status === 401) {
            setIsEmailExist(true)
          }

          window.sessionStorage.setItem('a', false)
          setLoading(false)
        }
      )
      setEmailError(false)
      setIsEmailExist(false)
    }
  }
  const handleEmail = (e) => {
    handleEmailValidation(e.target.value) ? setIsEmailValid(true) : setIsEmailValid(false)
    setEmail(e.target.value)
  }
  const handleNewFlow = () => {
    setEmail(window.localStorage.getItem('e'))
  }
  useEffect(() => {
    handleNewFlow()
  }, [])
  return (
    <div className="page--register">
      <div className="logo"></div>
      <div className="card-wrapper-row">
        <>
          <div className="success">
            <img src={emailSent} className="email-sent" alt="emaill-icon" />
            <div className="title">{t('register.success_email')}</div>
            <div className="weve-sent">{t('register.weve_sent')}</div>
            <div className="email">{email}</div>
            <div className="activate">{t('register.to_activate')}</div>
            <div className="locate">{t('register.spam_folder')}</div>
            <a to="/login" className="contact" href="https://support.intego.com/hc/en-us/requests/new" target="_blank" rel="noreferrer noopener">
              {t('register.contact_support')}
            </a>
          </div>
        </>
      </div>
    </div>
  )
}

export default Register
