import React, { useState, useContext } from 'react'
import { Button, Form, FormGroup, Input, InputGroup } from 'reactstrap'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import Icon from 'components/layouts/Icon'
import { $unsecurePost } from 'components/hooks/useAxios'
import { LoadingContext } from 'contexts/loading'
import toastConfig from 'configs/toastConfig'
import { config } from 'configs/constants'

const ChangeEmail = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useContext(LoadingContext)
  const [success, setSuccess] = useState(false)
  const [currPassFail, setCurrPassFail] = useState(false)
  const [validation, setValidation] = useState({
    uppercase: false,
    lowercase: false,
    digit: false,
    length: false,
    match: null,
    newPassIsSameAsCurr: false,
  })

  // Form State ans success
  const [updateData, setUpdateData] = useState({
    updatePassword: false,
    updatedPassword: false,
  })
  const initialValues = [
    {
      id: 'currPass',
      value: '',

      passwordVisible: false,
    },
    {
      id: 'newPass',
      value: '',

      passwordVisible: false,
    },
    {
      id: 'newPassConf',
      value: '',

      passwordVisible: false,
    },
  ]

  const [values, setValues] = useState(initialValues)
  const url = `${config.urls.ACCOUNT_URL}/api/user/change-password?`

  const toggleUpdateData = (update, shown, onSuccess = false) => {
    setUpdateData((prevState) => ({
      ...prevState,
      [update]: shown,
    }))
    setCurrPassFail(false)

    if (!onSuccess) {
      setSuccess(false)
    }
  }

  const handleInput = (e) => {
    const { name, value } = e.target
    let index = initialValues.findIndex((obj) => obj.id === name)
    let temp = [...values]
    temp[index].value = value
    if (name === 'newPass') {
      handlePasswordValidation(value)
    }
    setValues(temp)
  }

  const clearInputs = () => {
    setValues(initialValues)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let tempValidation = { ...validation }
    let temp = [...values]
    let allValid = true
    tempValidation.match = temp[1].value === temp[2].value
    temp.forEach((value) => {
      if (
        value.err === true ||
        value.value === '' ||
        value.sameAsCurrPass === true ||
        !tempValidation.digit ||
        !tempValidation.lowercase ||
        !tempValidation.uppercase ||
        !tempValidation.length ||
        tempValidation.match !== true
      ) {
        allValid = false
        setValidation(tempValidation)
        return
      }
    })

    if (allValid === true) {
      setLoading(true)
      setValidation(tempValidation)
      $unsecurePost(url, {
      	currentPassword:values[0].value,
      	newPassword:values[1].value,
      	newPassword_confirmation:values[2].value,
      	api_token:localStorage.h
       }).then(
        (result) => {
          setLoading(false)
          setSuccess(true)
          setCurrPassFail(false)
          clearInputs()
          toggleUpdateData('updatePassword', false, true)
        },
        (error) => {
          setLoading(false)
          setCurrPassFail(true)
          toastConfig.fire({
            icon: 'error',
            title: t('general.err'),
          })
        }
      )
    }
  }

  const handlePasswordVisible = (index) => {
    let temp = [...values]
    temp[index].passwordVisible = !temp[index].passwordVisible
    setValues(temp)
  }

  const handlePasswordValidation = (value) => {
    const patt = new RegExp(/\d/g)
    let tempValidation = { ...validation }
    let tempValues = [...values]
    // Checking for lowercase
    tempValidation.uppercase = value.toLowerCase() !== value
    // checking for upperCase
    tempValidation.lowercase = value.toUpperCase() !== value
    // checking a digit
    tempValidation.digit = patt.test(value)
    // checking for 8 characters
    tempValidation.length = value.length >= 8
    // Checking if the new pass is the same as the current password
    tempValidation.newPassIsSameAsCurr = tempValues[0].value === tempValues[1].value
    setValidation(tempValidation)
  }

  return (
    <>
      {/* Password Button */}
      {!updateData.updatePassword && (
        <Button color="success" className="btn-max update-password" onClick={() => toggleUpdateData('updatePassword', true)}>
          {t('account.change_password')}
        </Button>
      )}

      {/* Password change form */}
      {updateData.updatePassword && (
        <>
          <div className="choose-new choose-new-pass">{t('account.choose_new_pass')}</div>
          <div className={cx('profile-form profile-form-password', { 'profile-form-anim': updateData.updatePassword })} autoComplete="off">
            <Form>
              <FormGroup>
                <InputGroup>
                  <div className={values[0].err ? 'input-wrap red' : 'input-wrap '}>
                    <Input
                      type={values[0].passwordVisible ? 'text' : 'password'}
                      name="currPass"
                      autoComplete="off"
                      value={values[0].value}
                      onChange={(e) => {
                        handleInput(e, 'passwordConf')
                      }}
                      className={!values[0].value.length ? '' : 'focused'}
                    />
                    <div className="placeholder">{t('account.curr_pass')}</div>
                    {values[0].err === true && !validation.length && <span className="err-msg">{t('account.enter_password')}</span>}

                    <div
                      className="eye-icon"
                      onClick={() => {
                        handlePasswordVisible(0)
                      }}
                    >
                      <Icon icon={!values[0].passwordVisible ? 'eye' : 'hidePassword'} />
                    </div>
                  </div>
                  {/* NEW PASSWORD */}
                  <div className={values[1].err ? 'input-wrap red' : 'input-wrap '}>
                    <Input
                      type={values[1].passwordVisible ? 'text' : 'password'}
                      name="newPass"
                      autoComplete="off"
                      value={values[1].value}
                      onChange={handleInput}
                      className={!values[1].value.length ? '' : 'focused'}
                    />
                    <div className="placeholder">{t('account.new_pass')}</div>

                    {validation.newPassIsSameAsCurr && <span className="err-msg">{t('finish.newpass_same_as_currpass')}</span>}
                    <div
                      className="eye-icon"
                      onClick={() => {
                        handlePasswordVisible(1)
                      }}
                    >
                      <Icon icon={!values[1].passwordVisible ? 'eye' : 'hidePassword'} />
                    </div>
                  </div>

                  <InputGroup>
                    <div className={values[2].err ? 'input-wrap red' : 'input-wrap '}>
                      <Input
                        type={values[2].passwordVisible ? 'text' : 'password'}
                        name="newPassConf"
                        value={values[2].value}
                        onChange={handleInput}
                        className={!values[2].value.length ? '' : 'focused'}
                        autoComplete="off"
                      />
                      <div className="placeholder">{t('account.new_pass_conf')}</div>
                      {validation.match === false && <span className="err-msg">{t('account.no_match_pass')}</span>}

                      <div
                        className="eye-icon"
                        onClick={() => {
                          handlePasswordVisible(2)
                        }}
                      >
                        <Icon icon={!values[2].passwordVisible ? 'eye' : 'hidePassword'} />
                      </div>
                    </div>
                  </InputGroup>
                </InputGroup>
                <div className="form-btns">
                  <Button color="success" className=" me-2 save-btn" type="submit" onClick={handleSubmit}>
                    {t('account.change_password')}
                  </Button>
                  <Button color="secondary" className="btn-md" outline onClick={() => toggleUpdateData('updatePassword', false)}>
                    {t('global.cancel')}
                  </Button>
                </div>
              </FormGroup>
              <div className="password-checklist mt-4">
                <div className="checklist-title">{t('finish.checklist_title')}</div>
                <div className="checklist-item">
                  {validation.length ? (
                    <>
                      {' '}
                      <Icon icon={'greenCheck'} />
                    </>
                  ) : (
                    <div className="circle"></div>
                  )}
                  {t('finish.length')}
                </div>
                <div className="checklist-item">
                  {validation.uppercase ? (
                    <>
                      <Icon icon={'greenCheck'} />
                    </>
                  ) : (
                    <div className="circle"></div>
                  )}
                  {t('finish.upper_case')}
                </div>
                <div className="checklist-item">
                  {validation.lowercase ? (
                    <>
                      {' '}
                      <Icon icon={'greenCheck'} />
                    </>
                  ) : (
                    <div className="circle"></div>
                  )}
                  {t('finish.lower_case')}
                </div>
                <div className="checklist-item">
                  {validation.digit ? (
                    <>
                      {' '}
                      <Icon icon={'greenCheck'} />
                    </>
                  ) : (
                    <div className="circle"></div>
                  )}
                  {t('finish.digit')}
                </div>
              </div>
            </Form>
          </div>
        </>
      )}

      {/* Success message */}
      {success && (
        <div className="success-msg">
          <Icon icon={'passGreenCheck'}></Icon>
          {t('account.pass_changed')}
        </div>
      )}
      {currPassFail && <div className="fail-msg mb-md-2">{t('account.curr_pass_fail')}</div>}

      {/* Margin bottom */}
      <div className="mb-md-5" />
    </>
  )
}

export default ChangeEmail
