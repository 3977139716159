import React from 'react'
import BN_IS_X9 from '../../assets/media/products/BN_IS_X9_thumbnail.webp'
import BN_MCBS_X9 from '../../assets/media/products/BN_MCBS_X9_thumbnail.webp'
import BN_MPB_X9 from '../../assets/media/products/BN_MPB_X9_thumbnail.webp'
import BN_WMS_X9 from '../../assets/media/products/BN_WMS_X9_thumbnail.webp'
import CB_X9 from '../../assets/media/products/CB_X9_thumbnail.webp'
import NB_X9 from '../../assets/media/products/NB_X9_thumbnail.webp'
import VB_X9 from '../../assets/media/products/VB_X9_thumbnail.webp'
import WM_X9 from '../../assets/media/products/WM_X9_thumbnail.webp'
import WM2019_X9 from '../../assets/media/products/WM2019_X9_thumbnail.webp'
import vpnImg from '../../assets/media/products/thumbnail.webp'
import IAV_thumbnailImg from '../../assets/media/products/IAV_thumbnail.webp'
import defaultProduct from '../../assets/media/products/no-product.png'
const Icon = ({ icon, fill = 'currentColor', size = '1x', product = false }) => {
  if (fill === 'danger') fill = '#FC1502'
  if (fill === 'success') fill = '#38D694'
  if (fill === 'secondary') fill = '#adb5bd'
  if (product) {
    switch (icon) {
      case 'BN_IS_X9_thumbnail':
        return BN_IS_X9_img(fill)
      case 'BN_MCBS_X9_thumbnail':
        return BN_MCBS_X9_img(fill)
      case 'BN_MPB_X9_thumbnail':
        return BN_MPB_X9_img(fill)
      case 'BN_WMS_X9_thumbnail':
        return BN_WMS_X9_img(fill)
      case 'CB_X9_thumbnail':
        return CB_X9_img(fill)
      case 'NB_X9_thumbnail':
        return NB_X9_img(fill)
      case 'VB_X9_thumbnail':
        return VB_X9_img(fill)
      case 'WM_X9_thumbnail':
        return WM_X9_img(fill)
      case 'WM2019_X9_thumbnail':
        return WM2019_X9_img(fill)
      case 'IAV_thumbnail':
        return IAV_thumbnail(fill)
      case '_thumbnail':
        return vpn(fill)
      default:
        return defaultProduct_img(fill)
    }
  } else {
    switch (icon) {
      case 'account':
        return accountIcon(fill)
      case 'support':
        return supportIcon(fill)
      case 'devices':
        return devicesIcon(fill)
      case 'invoices':
        return invoicesIcon(fill)
      case 'knowledge':
        return knowledgeIcon(fill)
      case 'success':
        return successIcon(fill)
      case 'info':
        return infoIcon(fill)
      case 'breadcrumbs':
        return breadCrumbsIcon(fill)
      case 'downloadInvoice':
        return downloadInvoice(fill)
      case 'noInvoice':
        return noInvoice(fill)
      case 'trash':
        return trash(fill)
      case 'arrow':
        return arrow(fill)
      case 'eye':
        return eye(fill)
      case 'hidePassword':
        return hidePassword(fill)
      case 'passGreenCheck':
        return passGreenCheck(fill)
      case 'x':
        return x(fill)
      case 'greenCheck':
        return greenCheck(fill)
      case 'copyIcon':
        return copyIcon(fill)

      default:
        return <>'no-icon'</>
    }
  }
}

const accountIcon = (fill) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49">
      <g transform="translate(-.315 -.297)">
        <g fill="#fff" stroke="#030133" strokeWidth="1" transform="translate(.315 .297)">
          <circle cx="24.5" cy="24.5" r="24.5" stroke="none" />
          <circle cx="24.5" cy="24.5" r="24" fill="none" />
        </g>
        <g fill="none" stroke="#5fb527" strokeMiterlimit="10" strokeWidth="1.5" transform="translate(15.465 12.404)">
          <path
            d="M9.749 9.748h0a3.9 3.9 0 01-3.9-3.9V3.9a3.9 3.9 0 013.9-3.9h0a3.9 3.9 0 013.9 3.9v1.95a3.9 3.9 0 01-3.9 3.898z"
            data-name="Path 152"
          />
          <path
            d="M6.007 12.672a.7.7 0 01.567.311 3.893 3.893 0 006.348 0 .7.7 0 01.567-.311h0a6 6 0 016 6v2.739a1 1 0 01-1 1H1a1 1 0 01-1-1v-2.739a6 6 0 016-6z"
            data-name="Path 153"
          />
        </g>
      </g>
    </svg>
  )
}

const devicesIcon = (fill) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49">
      <g transform="translate(.12 -.297)">
        <g fill="#fff" stroke="#030133" strokeWidth="1" transform="translate(-.12 .297)">
          <circle cx="24.5" cy="24.5" r="24.5" stroke="none" />
          <circle cx="24.5" cy="24.5" r="24" fill="none" />
        </g>
        <g
          fill="none"
          stroke="#5fb527"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          data-name="Group 5707"
          transform="translate(14.481 15.446)"
        >
          <rect width="19" height="16" data-name="Rectangle 54" rx="1" transform="translate(.399 -.15)" />
          <path d="M0 0L19 0" data-name="Line 32" transform="translate(.399 12.85)" />
          <path d="M0 0H3V3H0z" data-name="Rectangle 55" transform="translate(8.399 15.85)" />
          <path d="M9 0L0 0" data-name="Line 33" transform="translate(5.399 18.85)" />
        </g>
      </g>
    </svg>
  )
}

const invoicesIcon = (fill) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49">
      <g transform="translate(-.272 -.297)">
        <g fill="#fff" stroke="#030133" strokeWidth="1" transform="translate(.272 .297)">
          <circle cx="24.5" cy="24.5" r="24.5" stroke="none" />
          <circle cx="24.5" cy="24.5" r="24" fill="none" />
        </g>
        <g fill="none" stroke="#5fb527" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" transform="translate(14.967 11.727)">
          <path d="M16.442 23.636H1.028A1.028 1.028 0 010 22.608V5.138L5.138 0h11.3a1.028 1.028 0 011.028 1.028v21.58a1.028 1.028 0 01-1.024 1.028z" />
          <path d="M5.138 0v4.111A1.028 1.028 0 014.11 5.139H0z" />
          <path d="M0 0L9 0" transform="translate(4.306 12.569)" />
          <path d="M0 0L9 0" transform="translate(4.306 18.569)" />
        </g>
      </g>
    </svg>
  )
}

const knowledgeIcon = (fill) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="51" viewBox="0 0 49 51">
      <g transform="translate(.258 .399)">
        <g fill="#fff" stroke="#030133" strokeWidth="1" transform="translate(-.258 -.399)">
          <ellipse cx="24.5" cy="25.5" stroke="none" rx="24.5" ry="25.5" />
          <ellipse cx="24.5" cy="25.5" fill="none" rx="24" ry="25" />
        </g>
        <g fill="none" stroke="#74be0d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" transform="translate(14.633 10.912)">
          <path d="M0 24.728V2.355A2.354 2.354 0 012.355 0h15.308a2.354 2.354 0 012.355 2.355v20.018H2.355" />
          <path d="M2.355 22.373h17.663c-2.355 1.178-2.355 3.533 0 4.71H2.355A2.354 2.354 0 010 24.728h0a2.354 2.354 0 012.355-2.355z" />
          <path d="M16.485 12.953L12.952 9.42 9.42 12.953V0h7.065z" />
        </g>
      </g>
    </svg>
  )
}

const supportIcon = (fill) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="51" viewBox="0 0 49 51">
      <g transform="translate(-.315 .399)">
        <g>
          <g fill="#fff" stroke="#030133" strokeWidth="1" transform="translate(.315 -.399)">
            <ellipse cx="24.5" cy="25.5" stroke="none" rx="24.5" ry="25.5" />
            <ellipse cx="24.5" cy="25.5" fill="none" rx="24" ry="25" />
          </g>
        </g>
        <g fill="none" stroke="#74be0d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" transform="translate(12.955 13.62)">
          <path d="M18.198 14.243l5.217 5.217a2.238 2.238 0 010 3.162l-.793.793a2.238 2.238 0 01-3.161 0l-5.217-5.217" />
          <path d="M5.888 9.843L.671 4.626a2.239 2.239 0 010-3.162l.793-.793a2.239 2.239 0 013.162 0l5.217 5.217" />
          <path d="M14.243 5.888L19.46.671a2.238 2.238 0 013.161 0l.793.793a2.238 2.238 0 010 3.162l-5.217 5.217" />
          <path d="M5.888 14.243L.671 19.46a2.239 2.239 0 000 3.162l.793.793a2.239 2.239 0 003.162 0l5.217-5.217" />
          <path d="M5.966 2.011a10.187 10.187 0 0112.153 0" />
          <path d="M5.966 22.074a10.187 10.187 0 0012.153 0" />
          <path d="M2.011 18.119a10.186 10.186 0 010-12.153" />
          <path d="M22.074 5.966a10.188 10.188 0 010 12.153" />
          <circle cx="6" cy="6" r="6" transform="translate(6.36 5.981)" />
        </g>
      </g>
    </svg>
  )
}

const successIcon = (fill) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.5" height="20.5" viewBox="0 0 20.5 20.5" className="checkmark">
      <g fill="none" stroke="#74be26" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" transform="translate(-959.75 -1127.75)">
        <circle cx="9.5" cy="9.5" r="9.5" transform="translate(960.5 1128.5)" className="checkmark__circle" />
        <path className="checkmark__check" d="M7 0L0 7" transform="translate(968.5 1134.5)" />
        <path className="checkmark__check" d="M0 0L4 3" transform="translate(964.5 1138.5)" />
      </g>
    </svg>
  )
}

const infoIcon = (fill) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="34" viewBox="0 0 26 34">
      <g fill="#ebebeb" stroke="#a7a7a7" transform="translate(-682 -435) translate(682 440)">
        <circle cx="13" cy="13" r="13" stroke="none" />
        <circle cx="13" cy="13" r="12.5" fill="none" />
      </g>
      <text
        fill="#a7a7a7"
        fontFamily="SourceSansPro-Bold, Source Sans Pro"
        fontSize="21"
        fontWeight="700"
        transform="translate(-682 -440) translate(692 444)"
        className="tspan_i"
      >
        <tspan x="0" y="22">
          i
        </tspan>
      </text>
    </svg>
  )
}

const breadCrumbsIcon = (fill) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="4.733" height="9.407" viewBox="0 0 4.733 9.407">
      <g transform="translate(-469 -104.999)">
        <path
          d="M14.169,16.862a.67.67,0,0,1-.516-1.1l3-3.594-2.9-3.6a.685.685,0,1,1,1.079-.845l3.238,4.023a.67.67,0,0,1,0,.851l-3.352,4.023A.67.67,0,0,1,14.169,16.862Z"
          transform="translate(455.502 97.543)"
          fill="#030133"
        />
      </g>
    </svg>
  )
}

const downloadInvoice = (fill) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.983" height="20.801" viewBox="0 0 19.983 20.801">
      <g id="Group_5813" data-name="Group 5813" transform="translate(-191.5 -287.5)">
        <g id="Group_5812" data-name="Group 5812" transform="translate(197.405 299.944)">
          <line
            id="Line_754"
            data-name="Line 754"
            x1="4"
            y2="5"
            transform="translate(4.095 -0.444)"
            fill="none"
            stroke="#5fb527"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <line
            id="Line_755"
            data-name="Line 755"
            x2="4"
            y2="5"
            transform="translate(0.095 -0.444)"
            fill="none"
            stroke="#5fb527"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
        <path
          id="Path_7826"
          data-name="Path 7826"
          d="M210.483,305.5v4.087a.824.824,0,0,1-.817.817H193.3a.809.809,0,0,1-.8-.817V305.5"
          transform="translate(0 -3.104)"
          fill="none"
          stroke="#5fb527"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_756"
          data-name="Line 756"
          y2="16"
          transform="translate(201.5 288.5)"
          fill="none"
          stroke="#5fb527"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  )
}

const noInvoice = (fill) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.983" height="20.801" viewBox="0 0 19.983 20.801">
      <g id="Group_5813" data-name="Group 5813" transform="translate(-191.5 -287.5)">
        <g id="Group_5812" data-name="Group 5812" transform="translate(197.405 299.944)">
          <line
            id="Line_754"
            data-name="Line 754"
            x1="4"
            y2="5"
            transform="translate(4.095 -0.444)"
            fill="none"
            stroke="#D8D8D8"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <line
            id="Line_755"
            data-name="Line 755"
            x2="4"
            y2="5"
            transform="translate(0.095 -0.444)"
            fill="none"
            stroke="#D8D8D8"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
        <path
          id="Path_7826"
          data-name="Path 7826"
          d="M210.483,305.5v4.087a.824.824,0,0,1-.817.817H193.3a.809.809,0,0,1-.8-.817V305.5"
          transform="translate(0 -3.104)"
          fill="none"
          stroke="#D8D8D8"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_756"
          data-name="Line 756"
          y2="16"
          transform="translate(201.5 288.5)"
          fill="none"
          stroke="#D8D8D8"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  )
}
const trash = (fill) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="28" viewBox="0 0 20 28">
      <g transform="translate(0.5 1)">
        <path
          d="M0,0H15a0,0,0,0,1,0,0V17a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V0A0,0,0,0,1,0,0Z"
          transform="translate(1.5 7)"
          fill="none"
          stroke="#cc0d18"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M1,0H17a1,1,0,0,1,1,1V4a0,0,0,0,1,0,0H0A0,0,0,0,1,0,4V1A1,1,0,0,1,1,0Z"
          transform="translate(0.5 3)"
          fill="none"
          stroke="#cc0d18"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M1,0H8A1,1,0,0,1,9,1V3A0,0,0,0,1,9,3H0A0,0,0,0,1,0,3V1A1,1,0,0,1,1,0Z"
          transform="translate(4.5)"
          fill="none"
          stroke="#cc0d18"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line y2="11" transform="translate(5.5 10)" fill="none" stroke="#cc0d18" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        <line y2="11" transform="translate(9.5 10)" fill="none" stroke="#cc0d18" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        <line y2="11" transform="translate(12.5 10)" fill="none" stroke="#cc0d18" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      </g>
    </svg>
  )
}
const x = (fill) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.707" height="14.707" viewBox="0 0 14.707 14.707">
      <g transform="translate(-903.146 -279.146)">
        <line x1="14" y2="14" transform="translate(903.5 279.5)" fill="none" stroke="#fff" strokeWidth="1" />
        <line x2="14" y2="14" transform="translate(903.5 279.5)" fill="none" stroke="#fff" strokeWidth="1" />
      </g>
    </svg>
  )
}
const arrow = (fill) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27.235" height="15.845" viewBox="0 0 27.235 15.845">
      <g transform="translate(1.413 1.413)">
        <line x1="12.204" y2="13.018" transform="translate(12.204)" fill="none" stroke="#030133" strokeLinecap="round" strokeWidth="2" />
        <line x2="12.204" y2="13.018" fill="none" stroke="#030133" strokeLinecap="round" strokeWidth="2" />
      </g>
    </svg>
  )
}
const eye = (fill) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16">
      <g transform="translate(-1488 -340)">
        <path
          d="M1511.5,348s-4,7.5-11.5,7.5-11.5-7.5-11.5-7.5,4-7.5,11.5-7.5S1511.5,348,1511.5,348Z"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <circle
          cx="4.5"
          cy="4.5"
          r="4.5"
          transform="translate(1495.5 343.5)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  )
}
const hidePassword = (fill) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20.412" viewBox="0 0 24 20.412">
      <g transform="translate(-1488 -241.794)">
        <path
          d="M1511.5,252s-4,7.5-11.5,7.5-11.5-7.5-11.5-7.5,4-7.5,11.5-7.5S1511.5,252,1511.5,252Z"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <circle
          cx="4.5"
          cy="4.5"
          r="4.5"
          transform="translate(1495.5 247.5)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line x1="17" y2="19" transform="translate(1491.5 242.5)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
      </g>
    </svg>
  )
}
const passGreenCheck = (fill) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.5" height="20.5" viewBox="0 0 20.5 20.5">
      <g transform="translate(-959.75 -1127.75)">
        <circle
          cx="9.5"
          cy="9.5"
          r="9.5"
          transform="translate(960.5 1128.5)"
          fill="none"
          stroke="#74be26"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          x1="7"
          y2="7"
          transform="translate(968.5 1134.5)"
          fill="none"
          stroke="#74be26"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          x2="4"
          y2="3"
          transform="translate(964.5 1138.5)"
          fill="none"
          stroke="#74be26"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  )
}
const BN_IS_X9_img = (fill) => {
  return <img src={BN_IS_X9} alt="product" />
}
const BN_MCBS_X9_img = (fill) => {
  return <img src={BN_MCBS_X9} alt="product" />
}
const BN_MPB_X9_img = (fill) => {
  return <img src={BN_MPB_X9} alt="product" />
}
const BN_WMS_X9_img = (fill) => {
  return <img src={BN_WMS_X9} alt="product" />
}
const CB_X9_img = (fill) => {
  return <img src={CB_X9} alt="product" />
}
const NB_X9_img = (fill) => {
  return <img src={NB_X9} alt="product" />
}
const VB_X9_img = (fill) => {
  return <img src={VB_X9} alt="product" />
}
const WM_X9_img = (fill) => {
  return <img src={WM_X9} alt="product" />
}
const WM2019_X9_img = (fill) => {
  return <img src={WM2019_X9} alt="product" />
}
const IAV_thumbnail = (fill) => {
  return <img src={IAV_thumbnailImg} alt="product" />
}
const vpn = (fill) => {
  return <img src={vpnImg} alt="product" />
}
const defaultProduct_img = (fill) => {
  return <img src={defaultProduct} alt="product" />
}
const greenCheck = (fill) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7156 0.750212C12.0837 1.09413 12.0961 1.66385 11.7434 2.02272L4.66645 9.22271C4.49471 9.39745 4.25802 9.49737 4.00982 9.49995C3.76161 9.50252 3.5228 9.40753 3.34729 9.2364L0.270363 6.2364C-0.090121 5.88492 -0.090121 5.31508 0.270363 4.9636C0.630847 4.61213 1.21531 4.61213 1.57579 4.9636L3.98596 7.31352L10.4105 0.777286C10.7632 0.418418 11.3475 0.406296 11.7156 0.750212Z"
        fill="#74BE0D"
      />
    </svg>
  )
}
const copyIcon = (fill) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clip-path="url(#clip0_447_9658)">
        <path
          d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12Z"
          fill={fill}
        />
        <path
          d="M17.5 16V6.5C17.5 6.22386 17.2761 6 17 6H9.5C9.22386 6 9 6.22386 9 6.5V16C9 16.2761 9.22386 16.5 9.5 16.5H17C17.2761 16.5 17.5 16.2761 17.5 16Z"
          stroke="#9D9D9D"
        />
        <path d="M7 8V18C7 18.2761 7.22386 18.5 7.5 18.5H15" stroke="#9D9D9D" stroke-linecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_447_9658">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Icon
