// Constants.js

const prod = {
  urls: {
    ACCOUNT_URL: 'https://account-be.intego.com',
  },
}

const dev = {
  urls: {
    ACCOUNT_URL: 'https://account-be-staging.intego.com',
  },
}

// export const config = prod
export const config = process.env.NODE_ENV === 'production' ? prod : dev
