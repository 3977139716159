import React from 'react'
import '../assets/style/pages/maintenance.scss'
import MaintenanceImage from '../assets/media/img/Maintains.svg'
const Maintenance = () => {
  return (
    <div className="maintenance">
      <img className="maintenance_image" src={MaintenanceImage} alt="Maintenance" />
      <h1>We'll be back soon!</h1>
      <h3>Sorry for the inconvenience. We're performing some maintenance at the moment. We'll be back up shortly!</h3>
      <span>The Intego Team</span>
    </div>
  )
}

export default Maintenance
