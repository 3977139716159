import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { $get } from 'components/hooks/useAxios'
import { LoggedInContext } from 'contexts/isLoggedIn'
import { LoadingContext } from 'contexts/loading'
import { config } from 'configs/constants'
const ConfirmEmail = () => {
  const [isLoggedIn, setIsLoggedIn] = useContext(LoggedInContext)
  const [loading, setLoading] = useContext(LoadingContext)
  const history = useHistory()
  let urlParams = new URL(window.location)
  let newEmail = urlParams.searchParams.get('email')
  let token = urlParams.searchParams.get('token')
  useEffect(() => {
    setLoading(true)
    $get(`${config.urls.ACCOUNT_URL}/api/confirm-new-email?email=${newEmail}&token=${token}`).then(
      (result) => {
        localStorage.removeItem('h')
        localStorage.removeItem('e')
        setIsLoggedIn(false)
        history.push('/login?success=2')
        setLoading(false)
      },
      (error) => {
        localStorage.removeItem('h')
        localStorage.removeItem('e')
        setIsLoggedIn(false)
        history.push('/login')
        setLoading(false)
      }
    )
  }, [])
  return <></>
}

export default ConfirmEmail
