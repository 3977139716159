import React, { useContext } from 'react'
import { Container, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { UseEmailContext } from 'contexts/userEmail'
import logo from 'assets/media/logo-navbar.svg'
import { LoggedInContext } from 'contexts/isLoggedIn'
import { $unsecurePost } from 'components/hooks/useAxios'
import { LoadingContext } from 'contexts/loading'
import LanguageSwitcher from 'components/layouts/LangSwitcher'
import { config } from 'configs/constants'
import i18n from 'i18n'
import { Link } from 'react-router-dom'

let url = 'https://account-be-staging.intego.com/api/user/logout'
const Header = () => {
  const [isLoggedIn, setIsLoggedIn] = useContext(LoggedInContext)
  const [loading, setLoading] = useContext(LoadingContext)
  const history = useHistory()
  const [userEmail] = useContext(UseEmailContext)
  const { t } = useTranslation()
  const handleDropDownLink = (item) => {
    if (item.id === 6 || item.id === 5 || item.id === 4 || item.id === 7) {
      if (item.id === 6) {
        $unsecurePost(`${config.urls.ACCOUNT_URL}/api/user/logout?api_token=${window.localStorage.getItem('h')}`).then(
          (result) => {
            setLoading(false)
            setIsLoggedIn(false)
            history.push('/start')
            localStorage.removeItem('t')
            localStorage.removeItem('h')
          },
          (error) => {
            setLoading(false)
          }
        )
      } else if (item.id === 4) {
        window.open(`https://support.intego.com/hc/${i18n.language !== 'en' ? `${i18n.language}` : `en-us`}/requests/new`, '_blank', 'noopener')
      } else if (item.id === 5) {
        window.open('https://www.intego.com/support', '_blank', 'noopener')
      } else if (item.id === 7) {
        window.open('https://www.intego.com/', '_blank', 'noopener')
      }
    } else {
      history.push(item.to, 'dropdown')
    }
  }
  let links = [
    { id: 1, to: '/account', text: 'account.title', className: 'item' },
    { id: 2, to: '/devices', text: 'devices.title', className: 'item' },
    { id: 3, to: '/invoices', text: 'invoices.title', className: 'item' },
    { id: 4, to: '/support', text: 'support.title', className: 'item' },
    { id: 5, to: '/knowledge', text: 'knowledge.title', className: 'item' },
    { id: 7, to: '', text: 'Intego.com', className: 'item ' },
    { id: 6, to: '', text: 'header.logout', className: 'item logout' },
  ]
  return (
    <>
      {isLoggedIn && (
        <header className="app-header">
          <Container>
            <div className="wrapper">
              <Link to="/welcome" className="logo-wrapper">
                <img src={logo} alt="intego logo" />
              </Link>

              <div className="user-wrapper">
                <strong> {userEmail} </strong>
              </div>
              <LanguageSwitcher isLoggedIn={true} />

              <div className="menu-wrapper">
                <UncontrolledDropdown direction="down">
                  <DropdownToggle className="toggle">
                    <div className="button-wrapper">
                      <div className="menu-icon"></div>
                    </div>
                  </DropdownToggle>

                  <DropdownMenu>
                    {links.map((link) => (
                      <DropdownItem className={link.className} onClick={() => handleDropDownLink(link)} key={link.id}>
                        {t(link.text)}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </Container>
        </header>
      )}
    </>
  )
}

export default Header
