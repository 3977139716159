import React from 'react'

const NotFound = ({ img, text, supportText }) => {
  return (
    <div className="card-wrapper-row ">
      <div className="not-found">
        <img src={img} alt="tower" />
        <div className="not-found-txt">{text}</div>
        <div className="register-support">
          <a
            href="https://support.intego.com/hc/en-us/articles/7344352890258-How-Do-I-Register-My-Device-"
            target="_blank"
            rel="noreferrer noopener"
            className="contact-support"
          >
            {supportText}
          </a>
        </div>
      </div>
    </div>
  )
}

export default NotFound
