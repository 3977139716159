import React, { useState, useContext } from 'react'
import { Button, FormGroup, Input, InputGroup, Popover, PopoverBody, InputGroupAddon, InputGroupText } from 'reactstrap'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { LoadingContext } from 'contexts/loading'
import { config } from 'configs/constants'
import Icon from 'components/layouts/Icon'
import { $unsecurePost } from 'components/hooks/useAxios'
import toastConfig from 'configs/toastConfig'
const ChangeEmail = () => {
  const { t } = useTranslation()
  const [isCurrPasswordType, setIsCurrPasswodType] = useState(true)
  const [isPassword, setIsPassword] = useState(true)
  let emaiilReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/ //eslint-disable-line
  const [loading, setLoading] = useContext(LoadingContext)
  const [success, setSuccess] = useState(false)

  const url = `${config.urls.ACCOUNT_URL}/api/user/change-email?`

  const initialValues = [
    {
      id: 'newEmail',
      value: '',
      err: false,
    },
    {
      id: 'newEmailConf',
      value: '',
      err: false,
    },
    {
      id: 'pass',
      value: '',
      err: false,
    },
  ]
  const [values, setValues] = useState(initialValues)

  // Form State ans success
  const [updateData, setUpdateData] = useState({
    updateEmail: false,
    updatedEmail: false,
  })

  const toggleUpdateData = (update, shown) => {
    setUpdateData((prevState) => ({
      ...prevState,
      [update]: shown,
    }))
    setPopoverOpen(false)
  }

  // Popover state
  const [popoverOpen, setPopoverOpen] = useState(false)
  const togglePopover = () => setPopoverOpen(!popoverOpen)

  const handleInput = (e) => {
    const { name, value } = e.target
    let index = initialValues.findIndex((obj) => obj.id === name)
    let temp = [...values]
    temp[index].value = value
    setValues(temp)
  }

  const checkInput = (e) => {
    const { name, value } = e.target
    let temp = [...values]
    if (name === 'newEmail') {
      emaiilReg.test(value) ? (temp[0].err = false) : (temp[0].err = true)
    } else if (name === 'newEmailConf') {
      temp[0].value !== temp[1].value ? (temp[1].err = true) : (temp[1].err = false)
    } else {
      value.length <= 0 ? (temp[2].err = true) : (temp[2].err = false)
    }
    setValues(temp)
  }
  const triggerLogout = () => {
    localStorage.removeItem('h')
    window.location.reload()
  }
  const handleSubmit = () => {
    let temp = [...values]
    let allValid = true
    temp.forEach((value) => {
      if (value.err === true || value.value === '') {
        allValid = false
        return
      }
    })
    if (allValid === true) {
      setLoading(true)
      $unsecurePost(url, {
        newEmail: values[0].value,
        newEmail_confirmation: values[1].value,
        password: values[2].value,
        api_token: localStorage.h,
      }).then(
        (result) => {
          setLoading(false)
          setSuccess(true)
          window.setTimeout(() => {
            triggerLogout()
          }, 3000)
        },
        (error) => {
          setLoading(false)
          if (error.status === 400) {
            toastConfig.fire({
              icon: 'error',
              title: t('account.pass_fail'),
            })
          } else if (error.status === 403) {
            toastConfig.fire({
              icon: 'error',
              title: t('account.email_used'),
            })
          } else {
            toastConfig.fire({
              icon: 'error',
              title: t('general.err'),
            })
          }
        }
      )
    }
  }
  return (
    <>
      {/* Email button */}
      {!updateData.updateEmail && (
        <Button color="success" className="btn-max update-email" onClick={() => toggleUpdateData('updateEmail', true)}>
          {t('account.change_email')}
        </Button>
      )}

      {/* Email change form */}
      {updateData.updateEmail && (
        <>
          <div className="choose-new">{t('account.choose_new')}</div>
          <div
            className={cx('profile-form profile-form-email', {
              'profile-form-anim': updateData.updateEmail,
            })}
            autoComplete="off"
          >
            <Button id="newEmailInfo" type="button" onMouseEnter={togglePopover} onMouseLeave={togglePopover} color="blank">
              <Icon icon="info" />
            </Button>
            <FormGroup>
              <InputGroup>
                <div className={values[0].err ? 'input-wrap red' : 'input-wrap '}>
                  <Input
                    type="text"
                    name="newEmail"
                    autoComplete="off"
                    value={values[0].value}
                    onChange={handleInput}
                    onBlur={checkInput}
                    className={!values[0].value.length ? '' : 'focused'}
                  />
                  <div className="placeholder">{t('account.new_email')}</div>
                  {values[0].err === true && <span className="err-msg">{t('login.invaild')}</span>}
                </div>

                <div className={values[1].err ? 'input-wrap red' : 'input-wrap '}>
                  <Input
                    type="text"
                    name="newEmailConf"
                    autoComplete="off"
                    value={values[1].value}
                    onChange={handleInput}
                    onBlur={checkInput}
                    className={!values[1].value.length ? '' : 'focused'}
                  />
                  <div className="placeholder">{t('account.confirm_email')}</div>
                  {values[1].err === true && <span className="err-msg">{t('account.no_match')}</span>}
                </div>

                <div className="tooltip-input">
                  <Popover placement="right" isOpen={popoverOpen} target={'newEmailInfo'} hideArrow={false} fade={true} flip={true}>
                    <PopoverBody className={'dark'}>{t('account.change_email_tooltip')}</PopoverBody>
                  </Popover>
                </div>

                <InputGroup>
                  <div className={values[2].err ? 'input-wrap red' : 'input-wrap '}>
                    <Input
                      type={isPassword ? 'password' : 'text'}
                      name="pass"
                      value={values[2].value}
                      onChange={handleInput}
                      onBlur={checkInput}
                      className={!values[2].value.length ? '' : 'focused'}
                    />
                    <div className="placeholder">{t('account.password')}</div>
                    <div
                      className="eye-icon"
                      onClick={() => {
                        setIsPassword(!isPassword)
                      }}
                    >
                      <Icon icon={isPassword ? 'eye' : 'hidePassword'} />
                    </div>
                    {values[2].err === true && <span className="err-msg">{t('login.enter_password')}</span>}
                  </div>
                </InputGroup>
              </InputGroup>
              <div className="form-btns">
                <Button color="success" className="save-btn me-2" type="submit" onClick={handleSubmit}>
                  {t('account.change_email')}
                </Button>
                <Button color="secondary" className="btn-md" outline onClick={() => toggleUpdateData('updateEmail', false)}>
                  {t('global.cancel')}
                </Button>
              </div>
            </FormGroup>
          </div>
        </>
      )}

      {/* Success message */}
      {success && (
        <div className="success-msg">
          <Icon icon={'passGreenCheck'}></Icon>
          {t('account.email_sent')}
        </div>
      )}

      {/*Margin bottom*/}
      <div className="mb-md-5" />
    </>
  )
}

export default ChangeEmail
