import React from 'react'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import Icon from 'components/layouts/Icon'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Breadcrumbs = () => {
  const { t } = useTranslation()

  const breadcrumbs = useBreadcrumbs()
  return (
    <div className="breadcrumbs-wrap">
      {breadcrumbs.map(({ breadcrumb }, index) => (
        <div key={breadcrumb.key} className="breadcrumb-item-wrap">
          <Link to={breadcrumb.key} className={index === breadcrumbs.length - 1 ? 'item last' : 'item'}>
            {t(`breadcrumb.${breadcrumb.key.replace(`/`, ``)}`)}
          </Link>
          <div className="breadcrumbIcon">{index < breadcrumbs.length - 1 && <Icon icon={'breadcrumbs'}></Icon>}</div>
        </div>
      ))}
    </div>
  )
}

export default Breadcrumbs
