import React,{useState,createContext} from 'react';
export const UseEmailContext = createContext();

export const UseEmailProvider = props => {
    // const [{ data }] = useApiCall('subscriptions'); 
    const [userEmail,setUserEmail] = useState('');

    // useEffect(()=>{
    //     setUserEmail(data);
    // },[data])
    return (
        <UseEmailContext.Provider value={[userEmail,setUserEmail]}>
            {props.children}
        </UseEmailContext.Provider>
    );
}