import React, { useContext, useEffect } from 'react'
import ResizeDetector from 'react-resize-detector'
import cx from 'classnames'
import AppRoute from './AppRoute'

import circle134 from '../assets/media/bg/circles/circle-134.svg'
import circle211 from '../assets/media/bg/circles/circle-211.svg'
import circle213 from '../assets/media/bg/circles/circle-213.svg'
import circle216 from '../assets/media/bg/circles/circle-216.svg'
import circle366 from '../assets/media/bg/circles/circle-366.svg'
import { BreadCrumbsProvider } from '../contexts/breadCrumbsContext'
import { UseEmailProvider } from '../contexts/userEmail'
import { LoadingProvider } from '../contexts/loading'
import { LoggedInProvider } from 'contexts/isLoggedIn'
import Loading from './Layout/loading'
import Swal from 'sweetalert2'
import { ErrContext, ErrProvider } from 'contexts/errorContext'
import { useUserActivityDuration } from 'components/hooks/useUserActivityDuration'
import { useTranslation } from 'react-i18next'

const BackgroundCircles = () => {
  return (
    <div className="background-circles">
      <div className="left">
        <img src={circle134} alt="circle134" className="circle134" />
        <img src={circle211} alt="circle211" className="circle211" />
        <img src={circle213} alt="circle213" className="circle213" />
        <img src={circle213} alt="circle213" className="circle213-2" />
        <img src={circle216} alt="circle216" className="circle216" />
        <img src={circle366} alt="circle366" className="circle366" />
      </div>

      <div className="right">
        <img src={circle134} alt="circle134" className="circle134" />
        <img src={circle211} alt="circle211" className="circle211" />
        <img src={circle213} alt="circle213" className="circle213" />
        <img src={circle216} alt="circle216" className="circle216" />
        <img src={circle366} alt="circle366" className="circle366" />
      </div>
    </div>
  )
}

const App = (props) => {
  const [t, i18n] = useTranslation()
  document.documentElement.lang = i18n.language

  const isUserActive = useUserActivityDuration(8, '/login')

  useEffect(() => {
    if (!isUserActive) {
      window.localStorage.removeItem('h')
    }
  }, [isUserActive])

  return (
    <>
      <ErrProvider>
        <LoadingProvider>
          <UseEmailProvider>
            <LoggedInProvider>
              <BreadCrumbsProvider>
                <Loading />
                <ResizeDetector
                  handleWidth
                  render={({ width }) => (
                    <>
                      <div
                        className={cx(
                          'app-container app-theme app-background-circles',
                          { 'container-width-xxl': width > 1366 },
                          { 'container-width-xl': width < 1200 },
                          { 'container-width-md': width < 993 },
                          { 'container-width-sm': width < 578 },
                          { 'container-width-xs': width < 360 }
                        )}
                      >
                        <BackgroundCircles />
                        <AppRoute userActive={isUserActive} />
                      </div>
                    </>
                  )}
                />
              </BreadCrumbsProvider>
            </LoggedInProvider>
          </UseEmailProvider>
        </LoadingProvider>
      </ErrProvider>
    </>
  )
}

export default App
