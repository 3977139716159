import React from 'react'
import Swal from 'sweetalert2'
import Icon from './Icon'


const ToastPopUp = ({ toastIcon = 'success', title = '',trigger='',btnIcon='',callback }) => {
  const Toast = Swal.mixin({
    toast: true,
    title:title,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    icon:toastIcon,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
  })

  const showToast = ( )=>{
    Toast.fire(); 
    callback();
  }

  return (
    <>
      <div className="toastBtn" onClick={showToast}>
        {trigger===''  ? <Icon icon={`${btnIcon}`}/>: trigger}
      </div>
    </>
  )
}
export default ToastPopUp
