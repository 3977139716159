import React,{useState,createContext} from 'react';
export const BreadCrumbsContext = createContext();

export const BreadCrumbsProvider = props => {
    const [breadCrumbs,setBreadCrumbs] = useState([{ id: 1, name: 'Welcome' }]);
    return (
        <BreadCrumbsContext.Provider value={[breadCrumbs,setBreadCrumbs]}>
            {props.children}
        </BreadCrumbsContext.Provider>
    );
}