import React, { Suspense, useContext, useEffect, useState } from 'react'
import { Route, Redirect, BrowserRouter, Switch, useHistory, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

// Load pages
import Main from './Main'
import Devices from './Devices'
import Account from './Account'
import Plan from './Plan'
import Invoices from './Invoices'
import Reset from './resetPassword/reset'
import Login from './Login/login'
import Register from './Register/Register'
import Forgot from './Forgot/forgot'
import Finalize from './finalize/finalize'
import VerifyEmail from './verifyEmail/verifyEmail'
import { $unsecurePost } from 'components/hooks/useAxios'
import { UseEmailContext } from 'contexts/userEmail'
import ConfirmEmail from './Account/confirmEmail'
import Maintenance from './maintenance'
import Start from './Start/Start'
// Load Layout
import LanguageSwitcher from '../components/layouts/LangSwitcher'
import Header from './Layout/Header'
import LoadingScreen from 'components/layouts/LoadingScreen'
import { LoggedInContext } from 'contexts/isLoggedIn'
import { ErrContext } from 'contexts/errorContext'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import toastConfig from 'configs/toastConfig'
import LoadingStatic from './Layout/loading-static'
import { LoadingContext } from 'contexts/loading'
import { config } from 'configs/constants'
import i18n from 'i18next'

const AppRoute = ({ userActive }) => {
  const [isLoggedIn, setIsLoggedIn] = useContext(LoggedInContext)
  const [userEmail, setUserEmail] = useContext(UseEmailContext)
  const [loading, setLoading] = useContext(LoadingContext)
  const [isLoading, setIsloading] = useState(true)
  const [err, setErr] = useContext(ErrContext)
  const [isInMaintenance, setIsInMaintenance] = useState(false)
  const { t } = useTranslation()
  let locale = i18n.language

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
  })

  if (err) {
    toastConfig.fire({
      icon: 'error',
      title: t('general.err'),
    })
    setErr(false)
  }

  useEffect(() => {
    if (!userActive) {
      window.location.replace(`/login`)
    }
  }, [userActive])

  useEffect(() => {
    setLoading(true)

    if (localStorage.h) {
      $unsecurePost(
        `${config.urls.ACCOUNT_URL}/api/verify-token?email=${window.localStorage.getItem('e')}&token=${window.localStorage.getItem('h')}`
      )
        .then(
          (result) => {
            setIsLoggedIn(true)
            setUserEmail(localStorage.getItem('e'))
            setLoading(false)
          },
          (error) => {
            localStorage.removeItem('e')
            localStorage.removeItem('h')
            setIsLoggedIn(false)
            setLoading(false)
          }
        )
        .finally(() => {
          setIsloading(false)
          setLoading(false)
        })
    } else {
      setIsloading(false)
      setLoading(false)
    }
  }, [])
  if (isInMaintenance) {
    return <Maintenance />
  }
  if (isLoading) {
    return <LoadingStatic />
  } else {
    if (!isLoggedIn) {
      return (
        <BrowserRouter basename={`/${i18n.language !== 'en' ? i18n.language : ''}`}>
          <LanguageSwitcher />
          <Header />
          <div className="container main-wrapper">
            <AnimatePresence exitBeforeEnter initial={false}>
              <Suspense fallback={<LoadingStatic />}>
                <Switch>
                  <Route path={'/signup'} render={() => <Register />} />
                  <Route exact path={'/finish'} render={() => <Finalize />} />
                  <Route path={'/password-reset'} component={Reset} />
                  <Route path={'/forgot'} component={Forgot} />
                  <Route path={'/verify-email'} component={VerifyEmail}></Route>
                  <Route path={'/confirm-new-email'} component={ConfirmEmail}></Route>
                  <Route exact path={'/login'} render={() => <Login />}></Route>
                  <Route path={'/maintenance'} component={Maintenance} />
                  <Route path={'/start'} render={() => <Start />} />
                  <Route render={() => <Redirect to="/start" />}></Route>
                </Switch>
              </Suspense>
            </AnimatePresence>
          </div>
        </BrowserRouter>
      )
    } else {
      return (
        <>
          <BrowserRouter basename={`/${i18n.language !== 'en' ? i18n.language : ''}`}>
            {' '}
            <Header />
            <div className="container main-wrapper">
              <AnimatePresence exitBeforeEnter initial={false}>
                <Suspense fallback={<LoadingScreen />}>
                  <Switch>
                    <Route path={'/welcome'} render={() => <Main />} />
                    <Route path={'/account/plan'} component={Plan} />
                    <Route path={'/account'} component={Account} />
                    <Route path={'/devices'} component={Devices} />
                    <Route path={'/invoices'} component={Invoices} />
                    <Route path={'/maintenance'} component={Maintenance} />
                    <Route render={() => <Redirect to="/welcome" render={() => <Main />} />} />
                  </Switch>
                </Suspense>
              </AnimatePresence>
            </div>
          </BrowserRouter>
        </>
      )
    }
  }
}

export default AppRoute
