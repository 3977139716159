import React, { useState, useContext, useEffect, useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, InputGroup } from 'reactstrap'
import { LoadingContext } from 'contexts/loading'
import { LoggedInContext } from 'contexts/isLoggedIn'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { $unsecurePost } from 'components/hooks/useAxios'
import { UseEmailContext } from 'contexts/userEmail'
import Icon from 'components/layouts/Icon'
import thumbsUp from 'assets/media/img/thumbsUp.webp'
import { config } from 'configs/constants'
import { handleEmailValidation } from 'utils/utils'
import { handleSavedEmail } from 'utils/utils'
import { useDocumentTitle } from '../../components/hooks/useDocumentTitle'

const Login = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [isPassword, setIsPassword] = useState(true)
  const [loading, setLoading] = useContext(LoadingContext)
  const [isLoggedIn, setIsLoggedIn] = useContext(LoggedInContext)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordTest, setPasswordTest] = useState(null)
  const [emailTest, setEmailTest] = useState(null)
  const [userEmail, setUserEmail] = useContext(UseEmailContext)
  const [loginFail, setLoginFail] = useState(false)
  const [IsUserNotActivated, setIsUserNotActivated] = useState(false)
  const [successParam, setsuccessParam] = useState(0)
  const [autofillFocused, setAutofillFocused] = useState('')

  const loginBtn = useRef(null)
  const emailRef = useRef(null)
  const passwordRef = useRef(null)

  useDocumentTitle(t('login.page_title'))

  const detectAutofill = (...args) => {
    setTimeout(() => {
      args.forEach((element) => {
        if (element?.current?.matches(':-internal-autofill-selected')) {
          setAutofillFocused('focused')
        }
      })
    }, 400)
  }

  let url = `${config.urls.ACCOUNT_URL}/api/login`
  const handleinput = (input, type) => {
    if (type === 'email') {
      setEmail(input.target.value)
    }
    if (type === 'password') {
      setPassword(input.target.value)
    }
  }
  const checkInput = () => {
    handleEmailValidation(email) ? setEmailTest(true) : setEmailTest(false)
    password.length >= 1 ? setPasswordTest(true) : setPasswordTest(false)
    return handleEmailValidation(email) && password.length >= 1
  }
  const handleLogIn = () => {
    if (checkInput()) {
      setLoading(true)
      $unsecurePost(url, { email, password }).then(
        (result) => {
          setLoading(false)
          setUserEmail(email)
          setIsLoggedIn(true)
          history.push('/welcome')
          window.localStorage.setItem('h', result.message)
          window.localStorage.setItem('e', email)
          window.sessionStorage.removeItem('a')
        },
        (error) => {
          if (error.status !== 409) {
            setLoading(false)
            setLoginFail(true)
          }

          if (error.status === 409) {
            setLoading(false)
            setIsUserNotActivated(true)
          }
        }
      )
    }
  }
  const handleEnterPress = (e) => {
    e.preventDefault()
    if (e.key === 'Enter') {
      loginBtn.current.click()
    }
  }

  useEffect(() => {
    detectAutofill(emailRef, passwordRef)
    if (handleSavedEmail) {
      setEmail(handleSavedEmail)
    }
  }, [])
  useEffect(() => {
    const urlParams = new URL(window.location)
    const successP = urlParams.searchParams.get('success')
    setsuccessParam(successP)
    // listen to the enter key
    document.addEventListener('keyup', handleEnterPress, true)
    return () => {
      document.removeEventListener('keyup', handleEnterPress)
    }
  }, [])

  return (
    <div className="page--login">
      {successParam && (
        <div className="success-wrap">
          <div className="thumbs-up">
            <img src={thumbsUp} alt="succses-img" />
          </div>
          <div className="success-text">
            {successParam === 1 && <div className="success-title">{t('login.success_title')}</div>}
            {successParam === 2 && <div className="success-title">{t('account.email_changed')}</div>}
            <div className="success-sub">{t('login.success_sub')}</div>
          </div>
        </div>
      )}
      <div className="logo"></div>
      <div className="card-wrapper-row">
        <div className="title">{t('login.title')}</div>
        <div className="sub-title"> {t('login.sub-title')}</div>
        {IsUserNotActivated && (
          <div className="login-fail not-activated">
            <Link to="/signup">{t('login.not_activated')}</Link>
          </div>
        )}
        <div className="inputs">
          <InputGroup>
            <div className={emailTest === false ? 'input-wrap red' : 'input-wrap'}>
              {}
              <Input
                onChange={(e) => {
                  handleinput(e, 'email')
                }}
                value={email}
                type="text"
                name="email"
                autoComplete="off"
                className={`${!email?.length ? '' : 'focused'} ${autofillFocused}`}
                innerRef={emailRef}
              />
              <div className="placeholder">{t('login.email')}</div>
              {emailTest === false && <span className="err-msg">{t('login.invaild')}</span>}
            </div>
            <div className="password-wrap">
              <div className={passwordTest === false ? 'input-wrap last-input red' : 'input-wrap last-input'}>
                <div className="eye-icon">
                  <Icon icon="eye" />
                </div>

                <Input
                  onChange={(e) => {
                    handleinput(e, 'password')
                  }}
                  value={password}
                  type={isPassword ? 'password' : 'text'}
                  name="password"
                  className={`${!password.length ? '' : 'focused'} ${autofillFocused} `}
                  innerRef={passwordRef}
                />

                <div className="placeholder">{t('login.password')}</div>
                <div
                  className="eye-icon"
                  onClick={() => {
                    setIsPassword(!isPassword)
                  }}
                >
                  <Icon icon={isPassword ? 'eye' : 'hidePassword'} />
                </div>
                {loginFail && <div className="login-fail">{t('login.fail')}</div>}

                {passwordTest === false && <span className="err-msg">{t('login.enter_password')}</span>}
              </div>
            </div>
          </InputGroup>
        </div>
        <div className="login-btn-wrap">
          <button id="login" ref={loginBtn} onClick={handleLogIn} className="btn btn-success login-btn">
            {t('login.login')}
          </button>
        </div>
        <div className="keep-me-signed">
          <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
          <div className="keep-me-signed-txt">{t('login.keep_me_signed')}</div>
          <div className="forgot-password">
            <Link to="/forgot">{t('login.forgot_password')}</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
