import Icon from './Icon'
import React from 'react'

const TitleHolder = ({ text, icon = '', styles = '', pageTitle = false }) => {

  return (
    <>
    
      <div className={styles?"title-wrapper sub":"title-wrapper"}>
      {pageTitle === true}
        {text && !!icon.length && (
          <div className="page-title page-title-with-icon">
            <div className="icon">
              <Icon icon={icon} />
            </div>
            <div className={`text ${styles}`}>{text}</div>
          </div>
        )}

        {text && !icon.length && (
          <div className="page-title page-title-separator">
            <div className={`text ${styles}`}>{text}</div>
          </div>
        )}

      </div>
    </>
  )
}

export default TitleHolder