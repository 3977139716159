import React, { useState, useEffect, useContext } from 'react'
import globe from '../../assets/media/icons/globe.svg'
import arrowDown from '../../assets/media/icons/arrow-down.svg'
import globeWhite from '../../assets/media/icons/globe-white.svg'
import arrowDownWhite from '../../assets/media/icons/arrow-down-white.svg'
import { useTranslation } from 'react-i18next'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { LoggedInContext } from 'contexts/isLoggedIn'
const LanguageSwitcher = () => {
  const languages = [
    {
      id: 1,
      code: 'En',
      broswerCode: 'en-US',
      name: 'English',
    },
    {
      id: 2,
      code: 'Fr',
      broswerCode: 'fr-FR',
      name: 'Français',
    },
    {
      id: 3,
      code: 'De',
      broswerCode: 'de-DE',
      name: 'Deutsch',
    },
    {
      id: 4,
      code: 'Ja',
      broswerCode: 'ja-JP',
      name: '日本語',
    },
    {
      id: 5,
      code: 'Es',
      broswerCode: 'es-ES',
      name: 'Español',
    },
  ]
  const [selectedLanguage, setSelectedLanguage] = useState(0)
  const [t, i18n] = useTranslation()

  const [isLoggedIn] = useContext(LoggedInContext)
  const handleLangChange = (langCode, langId) => {
    setSelectedLanguage(langId)
    if (i18n.language !== langCode.toLowerCase()) {
      let newUrl = window.location.pathname.startsWith(`/${i18n.language}`)
        ? window.location.pathname.replace(`/${i18n.language}`, `/${langCode.toLowerCase()}`)
        : window.location.pathname.replace(`/`, `/${langCode.toLowerCase()}/`)
      window.location.replace(newUrl)
      localStorage.setItem('i18nextLng', langCode.toLowerCase())
      i18n.changeLanguage(langCode.toLowerCase())
    }
  }

  useEffect(() => {
    languages.forEach((lang, index) => {
      if (i18n.language.toLowerCase().includes(lang.code.toLowerCase())) {
        setSelectedLanguage(languages[index].id - 1)
      }
    })
  }, [])
  return (
    <>
      <div className={`language_switcher_wrap${isLoggedIn ? ' header_style' : ''}`}>
        <div className="language_switcher">
          <UncontrolledDropdown>
            <DropdownToggle className="language_switcher">
              <div className="globe_icon">
                <img src={isLoggedIn ? globeWhite : globe} alt="Globe icon" />
              </div>
              <div className="selected_language">{languages[selectedLanguage].code}</div>

              <div className="arrow">
                <img src={isLoggedIn ? arrowDownWhite : arrowDown} alt="arrow icon" />
              </div>
            </DropdownToggle>
            <DropdownMenu>
              {languages.map((language) => (
                <DropdownItem
                  key={language.id}
                  onClick={() => handleLangChange(language.code, language.id - 1)}
                  className={`lang${language.id === languages.length ? ' last' : ''} ${language.id - 1 === selectedLanguage ? ' selected' : ''}`}
                >
                  {language.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    </>
  )
}

export default LanguageSwitcher
