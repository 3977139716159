import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

import enLocales from './assets/locales/en/translation.json'
import frLocales from './assets/locales/fr/translation.json'
import esLocales from './assets/locales/es/translation.json'
import jaLocales from './assets/locales/ja/translation.json'
import deLocales from './assets/locales/de/translation.json'

// the translations
const resources = {
  en: {
    translation: enLocales,
  },
  de: {
    translation: deLocales,
  },
  es: {
    translation: esLocales,
  },
  ja: {
    translation: jaLocales,
  },
  fr: {
    translation: frLocales,
  },
}

const options = {
  // order and from where user language should be detected
  order: ['cookie', 'navigator', 'localStorage', 'path', 'subdomain', 'htmlTag'],

  // cache user language on
  caches: ['localStorage', 'cookie'],

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,
}

const langs = ['fr', 'de', 'ja', 'es']

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next

  .init({
    detection: options,
    resources,
    // lng: ['en', 'fr', 'de', 'ja', 'es'],
    fallbackLng: 'en', // use en if detected lng is not available
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

i18n.on('initialized', async (lang) => {
  // when user arrives to the site with a language in the url
  let urlLang = await getLangFromUrl()
  if (urlLang !== i18n.language) {
    i18n.changeLanguage((await urlLang).toLowerCase())
  }
})

const getLangFromUrl = async () => {
  let path = window.location.pathname
  let urlLang = path.substring(0, 4)
  let langIsDifferentFromEN = false
  for (let i = 0; i < langs.length; i++) {
    const element = langs[i]
    if (urlLang === `/${element}/`) {
      console.log(urlLang, element)
      urlLang = element
      langIsDifferentFromEN = true
    }
  }
  if (langIsDifferentFromEN) {
    return urlLang
  } else {
    return 'en'
  }
}

export default i18n
