import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, InputGroup } from 'reactstrap'
import { LoadingContext } from 'contexts/loading'
import { Link } from 'react-router-dom'
import { $unsecurePost } from 'components/hooks/useAxios'
import support from 'assets/media/support.svg'
import emailSent from 'assets/media/email-sent.svg'
import { config } from 'configs/constants'
import grayArrow from '../../assets/media/icons/gray-arrow.svg'
import { useEffect } from 'react'
const Forgot = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useContext(LoadingContext)
  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(false)
  const url = `${config.urls.ACCOUNT_URL}/api/forgot-password?email=${email}`

  const checkUser = () => {
    setLoading(true)
    $unsecurePost(url).then(
      (result) => {
        setLoading(false)
        setSuccess(true)
      },
      (error) => {
        setError(true)
        setLoading(false)
      }
    )
  }

  useEffect(() => {
    if (window.localStorage.getItem('e')) {
      setEmail(window.localStorage.getItem('e'))
    }
  }, [])
  return (
    <div className="page--forgot">
      <div className="logo"></div>
      <div className="card-wrapper-row">
        <Link to="/login" className="back-to-login">
          <img src={grayArrow} alt="arrow" />
          {t('forgot.back_to_login')}
        </Link>
        {success === false && (
          <>
            <div className="title">{t('forgot.title')}</div>
            <div className="sub-title">{!error ? t('forgot.sub-title') : t('register.cant_locate')}</div>
            <div className={error ? 'inputs error-input' : 'inputs'}>
              <InputGroup>
                <div className="input-wrap">
                  <Input
                    value={email}
                    type="text"
                    name="email"
                    autoComplete="off"
                    onChange={(e) => setEmail(e.target.value)}
                    className={!email.length ? '' : 'focused'}
                  />
                  <div className="placeholder">{t('login.email')}</div>
                </div>
              </InputGroup>
            </div>

            <div className="login-btn-wrap">
              <button onClick={checkUser} className="btn btn-success register-btn">
                {t('forgot.continue')}
              </button>
            </div>

            {error && (
              <div className="support">
                <img src={support} alt="support-icon" />
                <div className="support-txt">{t('register.problem')}</div>
                <a href="https://support.intego.com/hc/requests/new" target="_blank" rel="noreferrer noopener" className="contact-support">
                  {t('register.contact_support')}
                </a>
              </div>
            )}
          </>
        )}
        {success && (
          <>
            <div className="success">
              <img src={emailSent} alt="emaill-icon" />
              <div className="title">{t('forgot.success_title')}</div>
              <div className="email">{email}</div>
              <div className="activate">{t('forgot.follow')}</div>
              <div className="locate">{t('register.spam_folder')}</div>
              <a
                href="https://support.intego.com/hc/en-us/requests/new"
                target="_blank"
                rel="noreferrer noopener"
                className="verified"
                to="/login"
              >
                {t('register.contact_support')}
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Forgot
