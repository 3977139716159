import axios from 'axios'

/**
 * Submit secure post
 * @param url
 * @param data
 * @returns {Promise<T>}
 */
export const $post = async (url, data) => {
  return await axios({
    method: 'POST',
    url: url,
    data: data,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'text/plain; charset=utf-8',
      // Authorization: `Bearer ${token}`,
    },
    withCredentials: false,
  })
    .then((response) => {
      return Promise.resolve(response.data)
    })
    .catch((error) => {
      return Promise.reject(error.response)
    })
}

/**
 * Submit Unsecure post
 * @param url
 * @param data
 * @param _secure
 * @returns {Promise<T>}
 */
export const $unsecurePost = async (url, data, _secure = false) => {
  return await axios({
    method: 'POST',
    url: url,
    data: data,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    },
    withCredentials: false,
  })
    .then((response) => {
      return Promise.resolve(response.data)
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

/**
 * Submit Get data
 * @param url
 * @returns {Promise<T>}
 */
export const $get = async (url) => {
  return await axios({
    url: url,
    method: 'GET',
  })
    .then((response) => {
      return Promise.resolve(response?.data)
    })
    .catch((error) => {
      return Promise.reject({ ...error })
    })
}
