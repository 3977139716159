import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './configs/i18'
import './assets/style/App.scss'
import App from './Pages/App'
import reportWebVitals from './reportWebVitals'
import LoadingStatic from 'Pages/Layout/loading-static'
import './i18n'

ReactDOM.render(
  <Suspense fallback={<LoadingStatic />}>
    {/*<React.StrictMode>*/}
    <App />
    {/*</React.StrictMode>,*/}
  </Suspense>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
