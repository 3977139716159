import React, { useEffect, useContext, useState } from 'react'
import { LoadingContext } from 'contexts/loading'
import { $get } from 'components/hooks/useAxios'
import { useHistory } from 'react-router-dom'
import { UseEmailContext } from 'contexts/userEmail'
import { config } from 'configs/constants'
import i18n from 'i18next'

const VerifyEmail = () => {
  const [loading, setLoading] = useContext(LoadingContext) //eslint-disable-line
  const [userEmail, setUserEmail] = useContext(UseEmailContext) //eslint-disable-line
  const [error, setError] = useState('') //eslint-disable-line

  let url = ''
  const history = useHistory()

  const getUrlParams = () => {
    let urlParams = new URLSearchParams(window.location.search)
    let email = urlParams.get('email')
    let hash = urlParams.get('hash')
    let expires = urlParams.get('expires')
    let signature = urlParams.get('signature')
    let language = urlParams.get('language')
    url = `${config.urls.ACCOUNT_URL}/api/verify-email?email=${email}&expires=${expires}&hash=${hash}&language=${language}&signature=${signature}`
    saveHash(hash)
    setUserEmail(email)
  }
  const saveHash = (hash) => {
    window.localStorage.setItem('t', hash)
  }

  useEffect(() => {
    getUrlParams()
    setLoading(true)

    $get(url).then(
      (_result) => {
        setLoading(false)
        history.push('/finish')
      },
      (error) => {
        setError(error)
        setLoading(false)
        history.push('/login')
      }
    )
  }, [])
  return <></>
}

export default VerifyEmail
