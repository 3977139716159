import React,{useState,createContext} from 'react';
export const ErrContext = createContext();

export const ErrProvider = props => {
    const [err,setErr] = useState(false);

    return (
        <ErrContext.Provider value={[err,setErr]}>
            {props.children}
        </ErrContext.Provider>
    );
}