import { useTranslation } from 'react-i18next'
import closeIcon from 'assets/media/icons/close.svg'
import { Input } from 'reactstrap'
import { useState } from 'react'

export const IPP_Modal = ({ handleinput, loginBtn, passwordRef, emailRef, password, autofillFocused, handleIppReply, setIppModal }) => {
  const { t } = useTranslation()
  const [email, setEmail] = useState(localStorage.getItem('e'))
  return (
    <div className="ipp-modal-wrapper">
      <div className="login-form">
        <div
          className="close-modal"
          onClick={() => {
            setIppModal(false)
          }}
        >
          <img src={closeIcon} alt="close-login" />
        </div>
        <div className="title">{t('login.show_credentials')}</div>
        <div className="form">
          <div className="inputs">
            <div className="input-wrap">
              <Input
                name="email"
                value={email}
                autoComplete="Email"
                type="Email"
                className={`${!email.length ? '' : 'focused'} ${autofillFocused} `}
                onChange={(e) => {
                  handleinput(e, 'email')
                }}
                innerRef={emailRef}
              />
              <div className="placeholder">{t('login.email')}</div>
            </div>
            <div className="input-wrap">
              <Input
                name="password"
                value={password}
                type="password"
                autoComplete="password"
                className={`${!password.length ? '' : 'focused'} ${autofillFocused} `}
                onChange={(e) => {
                  handleinput(e, 'password')
                }}
                innerRef={passwordRef}
              />
              <div className="placeholder">{t('login.password')}</div>
            </div>

            <div className="login-btn-wrap">
              <button id="login" ref={loginBtn} onClick={handleIppReply} className="btn btn-success login-btn">
                {t('global.ok').toUpperCase()}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
