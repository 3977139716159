import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ContentHolder from 'components/layouts/ContentHolder'
import TitleHolder from 'components/layouts/TitleHolder'
import { Col, Container, Row } from 'reactstrap'
import Icon from 'components/layouts/Icon'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { useDocumentTitle } from '../../components/hooks/useDocumentTitle'

const Main = ({ title }) => {
  const { t } = useTranslation()
  const history = useHistory()
  let [lang, setLang] = useState(window.localStorage.getItem('i18nextLng'))
  const cardContent = [
    {
      id: 1,
      title: t('account.title'),
      paragraph: t('account.paragraph'),
      icon: 'account',
      link: 'account',
      className: 'account-card',
    },
    {
      id: 2,
      title: t('devices.title'),
      paragraph: t('devices.paragraph'),
      icon: 'devices',
      link: 'devices',
      className: 'devices-card',
    },
    {
      id: 3,
      title: t('invoices.title'),
      paragraph: t('invoices.paragraph'),
      icon: 'invoices',
      link: 'invoices',
      className: 'invoices-card',
    },
    {
      id: 4,
      title: t('support.title'),
      paragraph: t('support.paragraph'),
      icon: 'support',
      link: 'support',
      className: 'support-card',
    },
    {
      id: 5,
      title: t('knowledge.title'),
      paragraph: t('knowledge.paragraph'),
      icon: 'knowledge',
      link: 'knowledge',
      className: 'knowledge-card',
    },
  ]

  useDocumentTitle(t('welcome.page_title'));

  useEffect(() => {
    setLang(window.localStorage.getItem('i18nextLng'))
  }, [lang])
  const handleCardClick = (item) => {
    const lang = document.documentElement.lang
    if (item.id === 4) {
      window.open(`https://support.intego.com/hc/${lang}/requests/new`, '_blank', 'noopener')
    } else if (item.id === 5) {
      window.open(`https://support.intego.com/hc/${lang}`, '_blank', 'noopener')
    } else history.push(item.link, 'cards')
  }
  return (
    <div className="page--main">
      <ContentHolder>
        <TitleHolder text={t('welcome.title')} />
        <Container className="p-0">
          <Row>
            {cardContent.map((data, i) => (
              <Col key={i} lg={4} md={6} sm={12} className="card-wrapper">
                <div onClick={() => handleCardClick(data)}>
                  <div className={`card-nav  ${data.className}`}>
                    <div className="icon">
                      <Icon icon={data.icon} />
                    </div>

                    <div className="content">
                      <h3 className="title">{data.title}</h3>
                      <p>{data.paragraph}</p>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </ContentHolder>
    </div>
  )
}

export default Main
