import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import eyeIcon from 'assets/media/eyeIcon.svg'
import toastConfig from 'configs/toastConfig'
import Icon from 'components/layouts/Icon'
import { useState } from 'react'

export const IPP = ({ item, index, toggleModal, showHiddenData, serialNumber, username, ippPass, locked }) => {
  const { t } = useTranslation()
  const [usernameHover, setUsernameHover] = useState(false)
  const [passHover, setPassHover] = useState(false)
  const copyToClipBoard = (textToCopy) => {
    if (textToCopy !== '*********') {
      navigator.clipboard.writeText(textToCopy)
      toastConfig.fire({
        icon: 'success',
        title: t('account.copied'),
      })
    }
  }
  return (
    <div className="IPP-wrapper">
      <div className="login-credentials">
        <div className="login-text">{t('login.credentials')}</div>
        {(locked===1) && (
          <div
            className={`${'ipp-eye-icon'} ${username !== '*********' ? 'disabled' : ''}`}
            onClick={(e) => {
              toggleModal(item.serialNumber,index)
            }}
          ><img src={eyeIcon} alt="hidden-credentials" />
          </div>
        )}
      </div>
      <div className="credentials-content">
        <div className="username-text">
          <span className="field_type">{t('login.username')}:</span> <span>{username}</span>
          {(locked===0) && (
            <div
              className="copyIcon"
              onMouseLeave={() => setUsernameHover(false)}
              onMouseEnter={() => setUsernameHover(true)}
              onClick={() => copyToClipBoard(username)}
            >
              <Icon icon={'copyIcon'} fill={usernameHover ? '#E1E1E1  ' : '#F9F9FA'} />
            </div>
          )}
        </div>
        <div className="password-text">
          <span className="field_type">{t('account.password')}:</span> <span>{ippPass}</span>
          {(locked===0) && (
            <div
              className="copyIcon"
              onMouseLeave={() => setPassHover(false)}
              onMouseEnter={() => setPassHover(true)}
              onClick={() => copyToClipBoard(ippPass)}
            >
              <Icon icon={'copyIcon'} fill={passHover ? '#E1E1E1 ' : '#F9F9FA'} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
