import React, { useContext } from 'react'
import { LoadingContext } from 'contexts/loading'
import circle from 'assets/media/circle.svg'
const Loading = () => {
  const [loading] = useContext(LoadingContext)

  return (
    <div className={loading ? 'loading shown' : 'loading'}>
      <div className="loading-circles">
        <img src={circle} alt="circle" className="circle circle-1" />
        <img src={circle} alt="circle" className="circle circle-2" />
        <img src={circle} alt="circle" className="circle circle-3" />
      </div>
    </div>
  )
}

export default Loading
