import { useEffect, useState, useContext } from 'react'
import { UseEmailContext } from 'contexts/userEmail'
import { $get } from '../hooks/useAxios'
import { LoggedInContext } from 'contexts/isLoggedIn'
import { useHistory } from 'react-router'
import { config } from 'configs/constants'

const useApiCall = (setCall) => {
  const [error, setError] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [data, setData] = useState([])
  const [userEmail] = useContext(UseEmailContext)
  const [setIsLoggedIn] = useContext(LoggedInContext)
  const history = useHistory()
  let url = ''

  const call = async (setCall, id = null) => {
    switch (setCall) {
      case 'invoices':
        url = `${config.urls.ACCOUNT_URL}/api/user/invoices?api_token=${localStorage.h}`
        break
      case 'devices':
        url = `${config.urls.ACCOUNT_URL}/api/user/devices?api_token=${localStorage.h}&all`
        break
      case 'subscriptions':
        url = `${config.urls.ACCOUNT_URL}/api/user/subscriptions?api_token=${localStorage.h}&all`
        break
      default:
        url = `${config.urls.ACCOUNT_URL}/api/user/invoices?api_token=${localStorage.h}`
        break
    }
    try {
      $get(url).then(
        (result) => {
          setData(result)
          setIsLoaded(true)
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true)
          setError(error)
          //TODO: error handle function
          // handleError(statusCode)
          if (error.response.status === 401) {
            history.push('/login')
            localStorage.removeItem('h')
            localStorage.removeItem('e')
            setIsLoggedIn(false)
          }
        }
      )
    } catch (e) {}
  }

  useEffect(() => {
    call(setCall)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCall])

  return [{ error, isLoaded, data }, call]
}

export default useApiCall
