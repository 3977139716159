import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, InputGroup } from 'reactstrap'
import { LoadingContext } from 'contexts/loading'
import { UseEmailContext } from 'contexts/userEmail'
import passwordChanged from 'assets/media/password-changed.svg'
import { Link, useHistory } from 'react-router-dom'
import Icon from 'components/layouts/Icon'
import { $unsecurePost } from 'components/hooks/useAxios'
import toastConfig from 'configs/toastConfig'
import { config } from 'configs/constants'

const Reset = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useContext(LoadingContext)
  const [isPassword, setIsPassword] = useState(true)
  const [isPasswordConf, setIsPasswordConf] = useState(true)
  const [password, setPassword] = useState('')
  const [passwordConf, setPasswordConf] = useState('')
  const [userEmail, setUserEmail] = useContext(UseEmailContext)
  const [success, setSuccess] = useState(false)
  const [resetToken, setResetToken] = useState('')
  const url = `${config.urls.ACCOUNT_URL}/api/reset-password`

  const history = useHistory()
  const [validation, setValidation] = useState({ uppercase: false, lowercase: false, digit: false, length: false, match: null })

  const handleInput = (input, type) => {
    let value = input.target.value
    if (type === 'password') {
      setPassword(value)
      handlePasswordValidation(value)
    }
    if (type === 'passwordConf') {
      setPasswordConf(value)
    }
  }

  const handleSetPassword = () => {
    let tempValidation = { ...validation }

    // check for match
    tempValidation.match = password === passwordConf
    setValidation(tempValidation)
    if (tempValidation.digit && tempValidation.length && tempValidation.lowercase && tempValidation.uppercase && tempValidation.match) {
      setLoading(true)
      $unsecurePost(url,{
      	token:resetToken,
      	email:userEmail,
      	password:password,
      	password_confirmation:passwordConf
      	}).then(
        (result) => {
          setLoading(false)
          setSuccess(true)

          setTimeout(() => {
            history.push('/login')
          }, 2000)
        },
        (error) => {
          setLoading(false)
          toastConfig.fire({
            icon: 'error',
            title: t('general.err'),
          })
        }
      )
    }
  }

  const handlePasswordValidation = (value) => {
    const patt = new RegExp(/\d/g)
    let tempValidation = { ...validation }
    let tmpPassword = { ...passwordConf }
    // Checking for lowercase
    tempValidation.uppercase = value.toLowerCase() !== value
    // checking for upperCase
    tempValidation.lowercase = value.toUpperCase() !== value
    // checking a digit
    tempValidation.digit = patt.test(value)
    // checking for 8 characters
    tempValidation.length = value.length >= 8

    setValidation(tempValidation)
  }

  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search)
    setUserEmail(urlParams.get('email'))
    setResetToken(urlParams.get('token'))
  }, []) //eslint-disable-line

  return (
    <div className="page--reset">
      <div className="logo" />
      <div className="card-wrapper-row">
        {success === false && (
          <>
            <div className="title">{t('reset.title')}</div>
            <div className="email">{userEmail}</div>
            <div className="inputs">
              <InputGroup>
                <div className={'password-wrap input-wrap '}>
                  <Input
                    onChange={(e) => {
                      handleInput(e, 'password')
                    }}
                    value={password}
                    type={isPassword ? 'password' : 'text'}
                    name="password"
                    className={!password.length ? '' : 'focused'}
                  />
                  <div className="placeholder">{t('finish.password_place_holder')}</div>
                  <div
                    className="eye-icon"
                    onClick={() => {
                      setIsPassword(!isPassword)
                    }}
                  >
                    <Icon icon={isPassword ? 'eye' : 'hidePassword'} />
                  </div>
                </div>
                <div className={validation.match === false ? 'password-wrap error-input input-wrap' : 'password-wrap input-wrap'}>
                  <Input
                    onChange={(e) => {
                      handleInput(e, 'passwordConf')
                    }}
                    value={passwordConf}
                    type={isPasswordConf ? 'password' : 'text'}
                    name="password"
                    className={!passwordConf.length ? '' : 'focused'}
                  />
                  <div className="placeholder">{t('finish.confirm_place_holder')}</div>
                  <div
                    className="eye-icon"
                    onClick={() => {
                      setIsPasswordConf(!isPasswordConf)
                    }}
                  >
                    <Icon icon={isPasswordConf ? 'eye' : 'hidePassword'} />
                  </div>
                  {validation.match === false && <span className="err">{t('finish.no_match')}</span>}
                </div>
              </InputGroup>
            </div>
            <div className="password-checklist">
              <div className="checklist-title">{t('finish.checklist_title')}</div>
              <div className="checklist-item">
                <div className="status">
                  {validation.length ? (
                    <>
                      {' '}
                      <Icon icon={'greenCheck'} />
                    </>
                  ) : (
                    <div className="circle"></div>
                  )}
                </div>
                {t('finish.length')}
              </div>
              <div className="checklist-item">
                <div className="status">
                  {validation.uppercase ? (
                    <>
                      <Icon icon={'greenCheck'} />
                    </>
                  ) : (
                    <div className="circle"></div>
                  )}
                </div>
                {t('finish.upper_case')}
              </div>
              <div className="checklist-item">
                <div className="status">
                  {validation.lowercase ? (
                    <>
                      {' '}
                      <Icon icon={'greenCheck'} />
                    </>
                  ) : (
                    <div className="circle"></div>
                  )}
                </div>
                {t('finish.lower_case')}
              </div>
              <div className="checklist-item">
                <div className="status">
                  {validation.digit ? (
                    <>
                      {' '}
                      <Icon icon={'greenCheck'} />
                    </>
                  ) : (
                    <div className="circle"></div>
                  )}
                </div>

                {t('finish.digit')}
              </div>
            </div>

            <div className="login-btn-wrap">
              <button
                onClick={handleSetPassword}
                disabled={!validation.digit || !validation.length || !validation.lowercase || !validation.uppercase}
                className="btn btn-success register-btn"
              >
                {t('reset.create_new')}
              </button>
            </div>
          </>
        )}
        {success && (
          <>
            <div className="success">
              <img src={passwordChanged} alt="password-icon" />
              <div className="title success-title">{t('forgot.password-changed')}</div>
              <Link className="verified" to="/login">
                {t('forgot.back_to_login')}
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Reset
