import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Icon from 'components/layouts/Icon'

{/* AW-3325 */}
export const CancelSubscriptionModal = ({  trigger='', showInformative=false, callback, dialog=false }) => {
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation()
  const handleShowModal = () => {
    setShowModal(!showModal)
  }
  const handleYesClick = ()=>{
    callback();
    setShowModal(false);
  }
  return (
    <>
      <div className="modalBtn" onClick={handleShowModal}>{trigger}</div>
      {showModal && (
      <div className="cancel-subscription-modal-wrapper">
		<div className="background" onClick={() => {setShowModal(false)}}></div>
		<div className="container">
			<div className="top">
             	<div className="title">{t('subscription.cancel_subscription_title')}</div>
            </div>
            <div className="body">
            	{showInformative && (
              		<>
            		<div><span class="informative">{t('subscription.informative')}</span></div>
            		</>
               	)}
              	<div className="are-you-sure">{t('subscription.are_you_sure')}</div>             	
              	<div className="btns-row">
                {dialog && (
                  <>
					<div className="decline">
						<button className="btn me-3 btn-outline-secondary" onClick={() => {setShowModal(false)}}>{t('subscription.decline')}</button>
					</div>
					<div className="accept">
						<button className="btn me-3 btn-success" onClick={handleYesClick}>{t('subscription.confirm')}</button>
					</div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}