import React from 'react'
import Skeleton from 'react-loading-skeleton'
import currencyFormatter from 'currency-formatter'
import { useTranslation } from 'react-i18next'
import ContentHolder from 'components/layouts/ContentHolder'
import TitleHolder from 'components/layouts/TitleHolder'
import useApiCall from 'components/api/useApiCall'
import Icon from 'components/layouts/Icon'
import { UncontrolledTooltip } from 'reactstrap'
import ErrorHandler from 'Pages/Layout/errorHandler'
import NotFound from '../Layout/notFound'
import NoInvoices from 'assets/media/img/no_invoices.webp'
import { saveAs } from 'file-saver'

const Invoices = () => {
  const { t } = useTranslation()
  const [{ error, isLoaded, data }] = useApiCall('invoices')
  let headers = ['invoices.invoiceNum', 'invoices.date', 'invoices.price', 'invoices.pdf']
  if (isLoaded && !data?.message?.invoices?.length) {
    return (
      <>
        <ContentHolder>
          <TitleHolder text={t('invoices.title')} icon="invoices" back={'/'} pageTitle={true} />
          <NotFound img={NoInvoices} text={t('invoices.no_invoices')} />
        </ContentHolder>
      </>
    )
  }
  const savePdf = (url) => {
    saveAs(`${url}`, 'invoice.pdf')
  }
  return (
    <>
      <ContentHolder>
        <div className="page--invoices">
          <TitleHolder text={t('invoices.title')} icon="invoices" back={'/'} pageTitle={true} />
          <div className="invoices-cards-wrap mobile">
            {error && <ErrorHandler />}
            <div className="skel-wrap">{!isLoaded && data && <Skeleton count={4} className="invoices-skel-mobile" />}</div>

            {data?.message?.invoices?.map((item, k) => (
              <div key={k} className="invoices-card">
                <div className="download">
                  {item.invoiceURL !== null ? (
                    <span onClick={() => savePdf(item.downloadURL)} className="active">
                      <Icon icon={'downloadInvoice'}></Icon>
                    </span>
                  ) : (
                    <div className="non-active" id={`non-active-icon-${k}`}>
                      <Icon icon={'noInvoice'}></Icon>
                      <UncontrolledTooltip placement="top" target={`non-active-icon-${k}`} rel="noreferrer">
                        {t('invoices.no_pdf')}
                      </UncontrolledTooltip>
                    </div>
                  )}
                </div>
                <div className="title">{t('invoices.invoiceNum')}</div>
                <div className="reference">{item.reference}</div>
                <div className="date-wrap">
                  <div className="date">{item.date.split(' ')[0]}</div>
                  <div className="price">{currencyFormatter.format(item.amount, { code: item.currency })}</div>
                </div>
              </div>
            ))}
          </div>
          <div className={isLoaded ? 'card-wrapper-row desktop' : 'card-wrapper-row desktop loading-table'}>
            {error && <ErrorHandler />}

            <div className="table-top">
              {headers.map((header, k) => (
                <div key={k} className="table-header">
                  {t(header)}
                </div>
              ))}
            </div>
            {!isLoaded && <Skeleton count={4} className="invoices-skel " />}
            {data?.message?.invoices.map((item, k) => (
              <div key={k} className="table-item">
                <div className="reference item">{item.reference}</div>
                <span className="created item">{item.date.split(' ')[0]}</span>
                <span className="price item">{currencyFormatter.format(item.amount, { code: item.currency })}</span>
                <span className="pdf item">
                  <div className="download">
                    {item.invoiceURL !== null ? (
                      <a className="active" href={`${item.downloadURL}`} target="_blank" rel="noreferrer">
                        <Icon icon={'downloadInvoice'}></Icon>
                      </a>
                    ) : (
                      <div id={`non-active-icon-${item.reference}`} className="non-active">
                        <Icon icon={'noInvoice'}></Icon>
                        <UncontrolledTooltip placement="top" target={`non-active-icon-${item.reference}`} rel="noreferrer">
                          {t('invoices.no_pdf')}
                        </UncontrolledTooltip>
                      </div>
                    )}
                  </div>
                </span>
              </div>
            ))}
          </div>
        </div>
      </ContentHolder>
    </>
  )
}

export default Invoices
