import i18n from 'i18next'

export const handleStatusCode = (code) => {
  let showContact = false
  let err = 0
  let tooManyTries = false
  switch (code) {
    case 404:
      // email not found
      showContact = true
      err = 404
      break
    case 409:
      // Email confirmed but user didn't set password
      window.location = `/${i18n.language !== 'en' ? `${i18n.language}/` : ''}forgot`
      break
    case 402:
      // Email needs to be verified
      window.location = `/${i18n.language !== 'en' ? `${i18n.language}/` : ''}signup?newflow=1`
      break
    case 201:
      window.location = `/${i18n.language !== 'en' ? `${i18n.language}/` : ''}login?success=1`
      break
    case 202:
      window.location = `/${i18n.language !== 'en' ? `${i18n.language}/` : ''}signup?newflow=1`
      break
    case 400:
      // input error show err message
      err = 400
      break
    case 401:
      // account is activated redirect to login
      window.location = `/${i18n.language !== 'en' ? `${i18n.language}/` : ''}login`
      break
    case 429:
      tooManyTries = true
      break
    default:
      return false
  }
  return { showContact, err, tooManyTries }
}

export const handleEmailValidation = (email) => {
  let emailReg =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/ //eslint-disable-line
  return emailReg.test(email)
}

export const handleSavedEmail = () => {
  if (window.localStorage.getItem('e')) {
    return window.localStorage.getItem('e')
  }
  return null
}
