import React, { useEffect } from 'react'
import { useState, useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, InputGroup } from 'reactstrap'
import { LoadingContext } from 'contexts/loading'
import { config } from 'configs/constants'
import { handleStatusCode } from 'utils/utils'
import SupportImage from '../../assets/media/icons/headphones.svg'
import { handleEmailValidation } from 'utils/utils'
import toastConfig from 'configs/toastConfig'
import i18n from 'i18next'

const LoginHandler = ({ title, subTitle, inputs, cb, action, cta }) => {
  const emailRef = useRef(null)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { t } = useTranslation()
  const [err, setErr] = useState(false)
  const [loading, setLoading] = useContext(LoadingContext)
  const [isEmailValid, setIsEmailValid] = useState(false)
  let url = `${config.urls.ACCOUNT_URL}/api/can-register?email=${encodeURIComponent(email)}&language=${i18n.language}`
  const loginBtn = useRef(null)
  const handleClick = async (action) => {
    setLoading(true)
    const urlParams = new URL(window.location)
    const qa = urlParams.searchParams.get('qa')

    if (isEmailValid) {
      window.localStorage.setItem('e', email)

      if (action === 'check-email') {
        try {
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json; charset=utf-8',
            },
          })

          if (handleStatusCode(+qa ? +qa : response.status).err !== 0) {
            setErr(handleStatusCode(+qa ? +qa : response.status).err)
          } else if (handleStatusCode(+qa ? +qa : response.status).tooManyTries === true) {
            toastConfig.fire({
              icon: 'error',
              title: t('errors.went_wrong'),
            })
          }

          setLoading(false)
        } catch (err) {
          console.log(err)
        }
      }
    }
  }
  const handleEmailCheck = (email) => {
    setEmail(email)
    setIsEmailValid(handleEmailValidation(email))
  }
  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      loginBtn.current.click()
    }
  }
  useEffect(() => {
    document.addEventListener('keyup', handleEnterPress)
    return () => {
      document.removeEventListener('keyup', handleEnterPress)
    }
  })

  return (
    <div className="login-handler">
      <div className="card-wrapper-row new_flow">
        {title && <h1 className="title">{t(`${title}`)}</h1>}
        {subTitle && <h3 className="sub-title">{t(`${subTitle}`)}</h3>}
        {inputs && inputs.length && (
          <InputGroup className={`input-wrap ${err ? 'red' : ''} `}>
            {inputs.map((input, index) =>
              input === 'email' ? (
                <Input
                  value={email}
                  placeholder={t('login.email')}
                  key={index}
                  type="text"
                  name="email"
                  autoComplete="off"
                  onChange={(e) => handleEmailCheck(e.target.value)}
                  className="input"
                />
              ) : input === 'password' ? (
                <Input
                  className="input"
                  value={password}
                  placeholder={t('login.password')}
                  key={index}
                  type="password"
                  name="password"
                  autoComplete="off"
                  onChange={(e) => setPassword(e.target.value)}
                />
              ) : (
                <></>
              )
            )}
            {err && <span className="err-msg">{t('register.email_not_found')}</span>}
          </InputGroup>
        )}
        <button className={'btn btn-success register-btn'} ref={loginBtn} disabled={!isEmailValid} onClick={() => handleClick(action)}>
          {cta}
        </button>
        {err === 404 && (
          <div className="support-wrap">
            <img src={SupportImage} alt="support" className="support-img" />
            <span className="support-desc">{t('register.contact_support')}</span>
            <a target="_blank" rel="noopener noreferrer" href="https://support.intego.com/hc/requests/new" className="support-link">
              {t('register.contact_support')}
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export default LoginHandler
