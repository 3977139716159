import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, InputGroup } from 'reactstrap'
import { LoadingContext } from 'contexts/loading'
import { UseEmailContext } from 'contexts/userEmail'
import Icon from 'components/layouts/Icon'
import { useHistory } from 'react-router-dom'
import { $unsecurePost } from 'components/hooks/useAxios'
import toastConfig from 'configs/toastConfig'
import { config } from 'configs/constants'
import { useEffect } from 'react'
import { useDocumentTitle } from '../../components/hooks/useDocumentTitle'

const Finalize = () => {
  const { t } = useTranslation()

  // eslint-disable-next-line
  const [loading, setLoading] = useContext(LoadingContext) // eslint-disable-next-line
  const [userEmail, setUserEmail] = useContext(UseEmailContext) // eslint-disable-next-line
  const [error, setError] = useState('false')
  const [isPassword, setIsPassword] = useState(true)
  const [isPasswordConf, setIsPasswordConf] = useState(true)
  const [password, setPassword] = useState('')
  const [passwordConf, setPasswordConf] = useState('')
  const [passwordsMatch, setPasswordsMatch] = useState(null)
  const [validation, setValidation] = useState({ uppercase: false, lowercase: false, digit: false, length: false, match: false })
  const history = useHistory()
  const [email, setEmail] = useState('')
  let url = `${config.urls.ACCOUNT_URL}/api/register`

  useDocumentTitle(t('finish.page_title'));

  const handleInput = (input, type) => {
    let value = input.target.value
    if (type === 'password') {
      setPassword(value)
      handlePasswordValidation(value)
    }
    if (type === 'passwordConf') {
      setPasswordConf(value)
    }
  }

  const checkPassMatch = () => {
    if (passwordConf === password) {
      setPasswordsMatch(true)
      return true
    } else {
      setPasswordsMatch(false)
      return false
    }
  }

  const handlePasswordValidation = (value) => {
    const patt = new RegExp(/\d/g)
    let tempValidation = { ...validation }
    // Checking for lowercase
    tempValidation.uppercase = value.toLowerCase() !== value
    // checking for upperCase
    tempValidation.lowercase = value.toUpperCase() !== value
    // checking a digit
    tempValidation.digit = patt.test(value)
    // checking for 8 characters
    tempValidation.length = value.length >= 8
    setValidation(tempValidation)
  }

  const handleSetPassword = () => {
    let tempValidation = { ...validation }
    tempValidation.match = checkPassMatch()
    if (tempValidation.digit && tempValidation.length && tempValidation.lowercase && tempValidation.uppercase && tempValidation.match) {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
        $unsecurePost(url, {
          email: userEmail,
          password,
          password_confirmation: passwordConf,
          language: 'en',
          hash: localStorage.getItem('t'),
        }).then(
          (_result) => {
            setLoading(false)
            setError(false)
            history.push('/login?success=1')
            window.localStorage.removeItem('t')
          },
          (_error) => {
            setError(true)
            setLoading(false)
            toastConfig.fire({
              icon: 'error',
              title: t('general.err'),
            })
          }
        )
      }, 1000)
    }
  }
  useEffect(() => {
    setEmail(localStorage.getItem('e'))
  }, [])
  return (
    <div className="page--finish">
      <div className="logo" />
      <div className="card-wrapper-row">
        <>
          <div className="title">{t('finish.title')}</div>
          <div className="sub-title">{t('finish.sub-title')}</div>
          <div className="email">{email}</div>
          <div className="inputs">
            <InputGroup>
              <div className={'password-wrap input-wrap '}>
                <Input
                  onChange={(e) => {
                    handleInput(e, 'password')
                  }}
                  value={password}
                  type={isPassword ? 'password' : 'text'}
                  name="password"
                  className={!password.length ? '' : 'focused'}
                />
                <div className="placeholder">{t('finish.password_place_holder')}</div>
                <div
                  className="eye-icon"
                  onClick={() => {
                    setIsPassword(!isPassword)
                  }}
                >
                  <Icon icon={isPassword ? 'eye' : 'hidePassword'} />
                </div>
              </div>
              <div className={passwordsMatch === false ? 'password-wrap error-input input-wrap' : 'password-wrap input-wrap'}>
                <Input
                  onChange={(e) => {
                    handleInput(e, 'passwordConf')
                  }}
                  value={passwordConf}
                  type={isPasswordConf ? 'password' : 'text'}
                  name="password"
                  className={!passwordConf.length ? '' : 'focused'}
                />
                <div className="placeholder">{t('finish.confirm_place_holder')}</div>
                <div
                  className="eye-icon"
                  onClick={() => {
                    setIsPasswordConf(!isPasswordConf)
                  }}
                >
                  <Icon icon={isPasswordConf ? 'eye' : 'hidePassword'} />
                </div>
                {passwordsMatch === false && <span className="err">{t('finish.no_match')}</span>}
              </div>
            </InputGroup>
          </div>
          <div className="password-checklist">
            <div className="checklist-title">{t('finish.checklist_title')}</div>
            <div className="checklist-item">
              <div className="status">
                {validation.length ? (
                  <>
                    {' '}
                    <Icon icon={'greenCheck'} />
                  </>
                ) : (
                  <div className="circle"></div>
                )}
              </div>
              {t('finish.length')}
            </div>
            <div className="checklist-item">
              <div className="status">
                {validation.uppercase ? (
                  <>
                    <Icon icon={'greenCheck'} />
                  </>
                ) : (
                  <div className="circle"></div>
                )}
              </div>
              {t('finish.upper_case')}
            </div>
            <div className="checklist-item">
              <div className="status">
                {validation.lowercase ? (
                  <>
                    {' '}
                    <Icon icon={'greenCheck'} />
                  </>
                ) : (
                  <div className="circle"></div>
                )}
              </div>
              {t('finish.lower_case')}
            </div>
            <div className="checklist-item">
              <div className="status">
                {validation.digit ? (
                  <>
                    {' '}
                    <Icon icon={'greenCheck'} />
                  </>
                ) : (
                  <div className="circle"></div>
                )}
              </div>

              {t('finish.digit')}
            </div>
          </div>
          <div className="login-btn-wrap">
            <button
              onClick={handleSetPassword}
              disabled={!validation.digit || !validation.length || !validation.lowercase || !validation.uppercase}
              className="btn btn-success register-btn"
            >
              {t('finish.create')}
            </button>
          </div>
        </>
      </div>
    </div>
  )
}

export default Finalize
