
const LoadingScreen = () => {
  return (
    <div className="loader-container">
      <div className="loader-container-inner">
        <h5 className="mt-3 pt-3">Loading...</h5>
      </div>
    </div>
  )
}

export default LoadingScreen