import React, { useEffect, useState, useContext } from 'react'
import ContentHolder from 'components/layouts/ContentHolder'
import TitleHolder from 'components/layouts/TitleHolder'
import currencyFormatter from 'currency-formatter'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import useApiCall from 'components/api/useApiCall'
import { Row } from 'reactstrap'
import Skeleton from 'react-loading-skeleton'
import Support from 'assets/media/support.svg'
import Icon from 'components/layouts/Icon'
import { $unsecurePost } from 'components/hooks/useAxios'
import { LoadingContext } from 'contexts/loading'
import toastConfig from 'configs/toastConfig'
import { config } from 'configs/constants'
const Plan = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [selctedPlan, setSelectedPlan] = useState('')
  const [loading, setLoading] = useContext(LoadingContext)
  const [{ isLoaded, data }] = useApiCall('subscriptions')
  const [localData, setLoacalData] = useState()
  useEffect(() => {
    if (data?.message?.subscriptions.length) {
      // Marking the selected plan from the subscriptions page
      let item = data.message.subscriptions.filter((item) => item.reference === history.location.search.split('=').pop())
      setSelectedPlan(item[0].reference)
      // creating object for all plans
      let tmpPlansArr = [...data.message.subscriptions]
      tmpPlansArr.map((plan) => {
        if (plan.macSeats !== 0) {
          plan.macSeats < 5 ? (plan.userSelection = plan.macSeats + 2) : (plan.userSelection = plan.macSeats)
        } else {
          plan.winSeats < 5 ? (plan.userSelection = plan.winSeats + 2) : (plan.userSelection = plan.winSeats)
        }
      })
      setLoacalData(tmpPlansArr)
    }
  }, [data, history.location.search])

  const handleSeatsChange = (e, reference) => {
    const seats = e.target.value
    let tmpPlansArr = [...localData]
    tmpPlansArr.map((plan) => {
      if (plan.reference === reference) {
        plan.userSelection = parseInt(seats)
      }
    })
    setLoacalData(tmpPlansArr)
  }

  const handleUpgrade = (reference, sku, userSelection) => {
    const token = localStorage.getItem('h')
    const index = sku.lastIndexOf('_')
    let newSku = sku.split('')
    newSku[index - 1] = userSelection
    newSku = newSku.join('')
    const url = `${config.urls.ACCOUNT_URL}/api/user/subscription-upgrade?subscription=${reference}&plan=${newSku}&api_token=${token}`
    setLoading(true)
    $unsecurePost(url).then(
      (result) => {
        setLoading(false)
        toastConfig.fire({
          icon: 'success',
          title: t('devices.upgrade_success'),
        })
      },
      (error) => {
        setLoading(false)
        toastConfig.fire({
          icon: 'error',
          title: t('devices.upgrade_failed'),
        })
      }
    )
  }

  return (
    <div className="page--plan">
      <ContentHolder>
        <TitleHolder text={t('account.title')} icon="account" back={'/'} pageTitle={true} />
        <TitleHolder className="sub" text={t('plan.my_plan')} styles="subtitle" />
        {!isLoaded && (
          <>
            <Row className="card-wrapper-row col-12">
              <Skeleton count={6} />
            </Row>
            <Row className="card-wrapper-row col-12">
              <Skeleton count={6} />
            </Row>
            <Row className="card-wrapper-row col-12">
              <Skeleton count={6} />
            </Row>
          </>
        )}
        {data &&
          isLoaded === true &&
          localData.map((plan, index) => (
            <div key={plan.reference}>
              <Row className={plan.reference === selctedPlan ? 'card-wrapper-row col-12 selected mobile' : 'card-wrapper-row col-12 mobile'}>
                <div className="plan-top">
                  <div className="label">{t('plan.sub_details')}</div>
                </div>
                <div className="plan-wrapper">
                  <div className="product-img">
                    <Icon product={true} icon={plan.productImageName.split('.')[0]} />
                  </div>

                  <div className="plan-name bold">{plan.product}</div>
                  <div className="macs bold">
                    {plan.macSeats !== 0 ? (
                      <>
                        {plan.macSeats} {t('plan.macs')}
                      </>
                    ) : (
                      <>
                        {' '}
                        {plan.winSeats} {t('plan.pcs')}
                      </>
                    )}
                  </div>
                  <div className="years bold">
                    {plan?.period / 12} {t('plan.year')}{' '}
                    {currencyFormatter.format(plan?.renewalPrice, { code: plan?.renewalPriceCurrency })} {t('plan.yearly')}
                  </div>

                  <div className="seats label">
                    {plan?.license.serialNumber && (
                      <>
                        {t('plan.seats')}{' '}
                        <span className="bold">
                          {plan.macSeats !== 0 ? (
                            <>
                              {plan?.license && plan?.license?.seatsUsed}/{plan.macSeats}
                            </>
                          ) : (
                            <>
                              {' '}
                              {plan?.license && plan?.license?.seatsUsed}/{plan.winSeats}
                            </>
                          )}
                        </span>
                      </>
                    )}
                  </div>

                  {plan.sku !== 'IPP_1Y' && (
                    <>
                      <div>
                        <select
                          defaultValue={plan.userSelection}
                          // onChange={(e) => handleSeatsChange(e, plan.reference)}
                          className="seats-dropdown"
                          disabled
                        >
                          <option value={1}>1</option>
                          <option value={3}>3</option>
                          <option value={5}>5</option>
                        </select>

                        <button disabled className="btn btn-success">
                          {t('plan.upgrade')}
                        </button>

                        {/* <button onClick={() => handleUpgrade(plan.reference, plan.sku, plan.userSelection)} className="btn btn-success"> */}
                        {/* {t('plan.upgrade')} */}
                        {/* </button> */}
                      </div>

                      <div className="contact">
                        <img className="contact-img" src={Support} alt="support" />
                        <div className="support-txt">{t('plan.support')}</div>
                        <a
                          href="https://support.intego.com/hc/en-us/requests/new"
                          target="_blank"
                          rel="noreferrer noopener"
                          className="link contact-support"
                        >
                          {t('register.contact_support')}
                        </a>
                      </div>
                    </>
                  )}
                </div>
              </Row>
              <Row className={plan.reference === selctedPlan ? 'card-wrapper-row col-12 selected desktop' : 'card-wrapper-row col-12 desktop'}>
                <div className="wrapper">
                  <div className="product">
                    <div className="product-img">
                      <Icon product={true} icon={plan.productImageName.split('.')[0]} />
                    </div>
                    <div className="product-content">
                      <div className="sub-details">{t('plan.sub_details')}</div>
                      <div className="plan-name bold">{plan.product}</div>
                      <div className="macs bold">
                        {plan.macSeats !== 0 ? (
                          <>
                            {plan.macSeats} {t('plan.macs')}
                          </>
                        ) : (
                          <>
                            {' '}
                            {plan.winSeats} {t('plan.pcs')}
                          </>
                        )}
                      </div>
                      <div className="years bold">
                        {plan?.period / 12} {t('plan.year')}{' '}
                        {currencyFormatter.format(plan?.renewalPrice, { code: plan?.renewalPriceCurrency })} {t('plan.yearly')}
                      </div>
                    </div>
                  </div>
                  {plan.sku !== 'IPP_1Y' && (
                    <div className="right-side-wrap">
                      <div className="coming-soon">
                        <select
                          defaultValue={plan.userSelection}
                          // onChange={(e) => handleSeatsChange(e, plan.reference)}
                          className="seats-dropdown"
                          disabled
                        >
                          <option value={1}>1</option>
                          <option value={3}>3</option>
                          <option value={5}>5</option>
                        </select>
                        {plan?.license.serialNumber && (
                          <div className="seats">
                            <span className="seats-txt">{t('plan.seats')}</span>
                            <span>
                              {plan.macSeats !== 0 ? (
                                <>
                                  {plan?.license && plan?.license?.seatsUsed}/{plan.macSeats}
                                </>
                              ) : (
                                <>
                                  {' '}
                                  {plan?.license && plan?.license?.seatsUsed}/{plan.winSeats}
                                </>
                              )}
                            </span>
                          </div>
                        )}

                        <button disabled className="btn btn-success">
                          {t('plan.upgrade')}
                        </button>

                        {/* <button onClick={() => handleUpgrade(plan.reference, plan.sku, plan.userSelection)} className="btn btn-success"> */}
                        {/* //   {t('plan.upgrade')} */}
                        {/* // </button> */}
                      </div>

                      <div className="contact">
                        <>
                          <img className="support-img" src={Support} alt="support" />
                        </>
                        <div className="links">
                          <div className="support-text">{t('plan.support')}</div>
                          <a
                            href="https://support.intego.com/hc/en-us/requests/new"
                            target="_blank"
                            rel="noreferrer noopener"
                            className="link contact-support"
                          >
                            {t('register.contact_support')}
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Row>
            </div>
          ))}
      </ContentHolder>
    </div>
  )
}

export default Plan
