import React from 'react'
import { motion } from 'framer-motion'
import BreadCrumbs from 'Pages/Layout/breadCrumbs'

const ContentHolder = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0, x: 200 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ type: 'spring', stiffness: 110, mass: 0.75 }}
      exit={{ opacity: 0, x: 200 }}
    >
      <BreadCrumbs />
      {children}
    </motion.div>
  )
}

export default ContentHolder
