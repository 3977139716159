import { useEffect, useState } from 'react'

/**
 * useUserActivityDuration(hours:number,path:string)
 * @param wantedHours = Need to be in typeof number, eg:1/2/3... - Default is set to 1 hour.
 * @param ignorePath = Need to be in typeof string and a '/' before, eg : '/login'.
 ** If no path to ignore, you can leave it empty.
 ** If it will be empty the tracker will work on all pages.
 *
 *** Return value is a boolean
 */

export const useUserActivityDuration = (wantedHours = 1, ignorePath = null) => {
  const HOUR = 1000 * 60 * 60
  let STORED_TIME = window.localStorage.getItem('at')
  const [isUserActive, setIsUserActive] = useState(true)
  const [isValidPath, setIsValidPath] = useState(false)

  useEffect(() => {
    const observer = new MutationObserver(function (mutations) {
      document.location.pathname !== ignorePath ? setIsValidPath(true) : setIsValidPath(false)
    })
    const config = { subtree: true, childList: true }
    observer.observe(document, config)

    if (isValidPath) {
      if (!STORED_TIME) {
        window.localStorage.setItem('at', Date.now())
      }
      movmentTracker()
      const timerInterval = setInterval(() => {
        timeTracker()
      }, wantedHours * HOUR)

      return () => {
        window.localStorage.removeItem('at')
        cleanUpMovmentTracker()
        clearInterval(timerInterval)
        observer.disconnect()
      }
    } else {
      window.localStorage.removeItem('at')
    }
  }, [isValidPath])

  const timeTracker = () => {
    STORED_TIME = window.localStorage.getItem('at')
    if ((STORED_TIME && new Date().getTime() - STORED_TIME > wantedHours * HOUR) || STORED_TIME === null) {
      setIsUserActive(false)
    }
  }

  const resetTimer = () => {
    window.localStorage.setItem('at', Date.now())
  }

  const movmentTracker = () => {
    window.addEventListener('mousemove', resetTimer)
    window.addEventListener('mousedown', resetTimer)
    window.addEventListener('touchstart', resetTimer)
    window.addEventListener('touchmove', resetTimer)
    window.addEventListener('click', resetTimer)
    window.addEventListener('keydown', resetTimer)
    window.addEventListener('scroll', resetTimer)
  }

  const cleanUpMovmentTracker = () => {
    window.removeEventListener('mousemove', resetTimer)
    window.removeEventListener('mousedown', resetTimer)
    window.removeEventListener('touchstart', resetTimer)
    window.removeEventListener('touchmove', resetTimer)
    window.removeEventListener('click', resetTimer)
    window.removeEventListener('keydown', resetTimer)
    window.removeEventListener('scroll', resetTimer)
  }

  return isUserActive
}
